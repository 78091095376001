import React from 'react'
import styled from 'styled-components'

import { FlexDivCentered } from '../components/UI'

import noResults from '../assets/images/no-results.svg'

const Container = styled.div`
  margin-bottom: 300px;
`

const NoResults = styled(FlexDivCentered)`
  width: 100%;
  justify-content: center;
  color: #000000;
  font-family: Roboto;
  font-size: 20px;
  font-weight: normal;
  line-height: 32px;
  white-space: pre;
  margin-bottom: ${({ end }) => end ? '400px' : '24px'};

  span {
    color: #999ca9;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: ${({ end }) => end ? '0px' : '32px'};
    font-size: 16px;
    line-height: 24px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 32px;
    white-space: normal !important;
  }
`

const NoResultsFound = ({ query, ...props }) => {
  return (
    <Container {...props}>
      <NoResults>
        <img src={noResults} alt="" />
      </NoResults>
      <NoResults end>
        No results found for <span>"{query}"</span>
      </NoResults>
    </Container>
  )
}

export default NoResultsFound
