import React, { Component } from 'react'
import styled from 'styled-components'

import DishesContainer from '../components/DishesContainer'
import NewSlider from '../components/NewSlider'
import CategoryCard from '../components/CategoryCard'

const SliderContainer = styled.div`
  width: 100%;
  margin-bottom: 140px;

  @media screen and (max-width: 767px) {
    margin-bottom: 90px;
  }
`

const Container = styled.div`
  width: 100%;
  height: auto;
  overflow: hidden;
`

const card = (props) => <CategoryCard {...props} />

class Menu extends Component {
  constructor(props) {
    super(props)

    this.state = {
      menu: []
    }
    this.refsCollection = {}
    this.createMenu = this.createMenu.bind(this)
    this.createCategories = this.createCategories.bind(this)
  }

  createMenu(menuObj) {
    const dishes = menuObj.dishes
    let list = []

    const menu = menuObj.categories.map(category => {
    list = dishes.filter(dish => dish.categoryId === category.id)

    return (
      {
        id: category.id,
        name: category.name,
        dishes: list
      }
      )
    })

    this.setState({ menu: menu })
  }

  createCategories() {
    const { dishes, categories } = this.props.menu

    if (categories) {
      return categories.map(category => {
        const dish = dishes.filter(dish => (dish.categoryId === category.id && dish.dishImages.length > 0))[0]
        const photoUrl = dish ?
        `url(https://cdn-dserve.imgix.net/${dish.dishImages[0].imageName}?w=260&h=253)` :
        false

        return {
          id: category.id,
          name: category.name,
          photoUrl: photoUrl
        }
      })
    } else {
      return []
    }
  }

  componentDidMount() {
    if (this.props.menu.categories) {
      this.createMenu(this.props.menu)
      this.createCategories()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.menu.categories && prevProps !== this.props) {
      this.createMenu(this.props.menu)
      this.createCategories()
    }
  }

  handleCategoryClick = (e) => {
    const component = e.currentTarget.dataset.value

    window.scrollTo(0, this.refsCollection[component].offsetTop)
  }

  render() {
    const categories = this.createCategories()

    return (
      <Container>
        <SliderContainer>
          <NewSlider
            menu
            title={'Our menu'}
            template={card}
            list={categories}
            click={this.handleCategoryClick}
            displayCount={4} />
        </SliderContainer>
        {this.state.menu.map(category => (
          <DishesContainer
            key={category.name}
            category={category}
            ref={(instance) => this.refsCollection[category.id] = instance} />
        ))}
      </Container>
    )
  }
}

export default Menu
