import React from 'react'

import AppContent from '../components/AppContent'

import dish from '../assets/images/dish.png'
import loading from '../assets/images/loading.png'
import menu from '../assets/images/menu.png'
import orderAndPay from '../assets/images/orderAndPay.png'
import dish2x from '../assets/images/dish@2x.png'
import loading2x from '../assets/images/loading@2x.png'
import menu2x from '../assets/images/menu@2x.png'
import orderAndPay2x from '../assets/images/orderAndPay@2x.png'
import sliderImg from '../assets/images/sliderImg.jpg'

const renderListing = () => (
  <div className="interest-row">
    <div className="gallery">
      <img src={sliderImg} style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt="cash"/>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-md-5 offset-md-7">
          <h3>Ready to update?</h3>
          <p>
          Let customers order themselves, save time and optimise labor costs
          </p>
          <a
            href="https://forms.gle/yrsD8aDrUeWPTxVY7"
            target="_blank"
            className="btn"
            rel="noopener noreferrer"
          >
            Book a demo
          </a>
        </div>
      </div>
    </div>
  </div>
)

// TODO: Refactor to normal solution, because this doesn't work on resize
const isMobile = window.innerWidth < 768

const SelfService = () => {
  return (
    <AppContent
      titleProps={{
        title: 'Digital Ordering Solutions',
        text: 'Everything you need in one place to boost sales, improve customer experience and cut labor costs!',
        src: dish,
        srcMobile: `${dish2x} 2x`,
        imgHeight: '117%',
        style: isMobile ? {
          width: '100%',
          paddingLeft: '10%',
          paddingRight: '10%'
        } : {},
        imgStyle: isMobile ? {
          margin: '40px 0 0 0'
        } : {},
      }}
      sectionOneProps={{
        title: 'Increase sales',
        text: 'Serve more customers during the lunch time with the faster ordering \nBigger orders: customers tend to order more when placing order themselves',
        src: orderAndPay,
        srcMobile: `${orderAndPay2x} 2x`,
      }}
      sectionTwoProps={{
        title: 'Improve customer service',
        text: 'Better experience: no need to go to the bar or flag down waiting staff \nNew customers: drive first time customers by offering them a new experience',
        src: menu,
        srcMobile: `${menu2x} 2x`,
      }}
      sectionThreeProps={{
        title: 'Cut labor costs',
        text: 'Less staff: tablets instead of cashier \nOrders could be sent to EPOS or other kitchen printer \nDrinks and food can be splited and sent to the right location \nInformative and easy to use analytics',
        src: loading,
        srcMobile: `${loading2x} 2x`,
      }}
      adProps={{
        title: 'Everything you need, in one place',
      }}
      renderRest={renderListing}
    />
  )
}

export default SelfService
