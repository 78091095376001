import React, { Component } from 'react'
import styled from 'styled-components'

const Card = styled.div`
  position: relative;
  height: 253px;
  width: 260px;
  background-image: ${({ photo }) => photo ? photo : null};
  background-size: cover;
  border-radius: 10px;
  margin: auto;
  overflow: hidden;
  cursor: pointer;

  &:active, &:focus, &:visited {
    outline: none;
  }
`
const CardTitle = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  background: linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
`

const Title = styled.div`
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  border: inherit;
  margin-bottom: 48px;
  z-index: 7;
  line-height: normal;
  max-height: 100%;
  padding: 0 15px;
`

const Hover = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  z-index: 4;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
`

class CategoryCard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hover: false
    }

    this.toggleMessage = this.toggleMessage.bind(this)
  }

  toggleMessage() {
    this.setState(({ hover }) => ({ hover: !hover }))
  }

  render() {
    const { id, photoUrl, click, name } = this.props

    return (
      <Card data-value={id} onMouseEnter={this.toggleMessage} onMouseLeave={this.toggleMessage} photo={photoUrl} onClick={click}>
        {this.state.hover ? <Hover/> : null}
        <CardTitle>
          <Title>{name}</Title>
        </CardTitle>
      </Card>
    )
  }
}

export default CategoryCard
