import React from "react"

import iconEmail from "../assets/images/icon-email.svg"
import iconCall from "../assets/images/icon-call.svg"

const Contacts = () => (
  <div className="body">
    <div className="contacts">
      <div className="contacts-headings">
        <h1 className="text-center">Get in touch</h1>
        <p className="text-center">
          We’re always here to help. Ask us anything, or share your feedback.
        </p>
      </div>

      <div className="contacts-content">
        <div className="contacts-content-col">
          <div className="icon">
            <img src={iconEmail} alt="email-icon" />
          </div>
          <div className="meta">
            <span className="meta-title">Write to us</span>
            <span className="meta-content">
              <a className="link" href="mailto:info@dserve.app">
                info@dserve.app
              </a>
            </span>
          </div>
        </div>

        <div className="contacts-content-col-separator" />

        <div className="contacts-content-col">
          <div className="icon">
            <img src={iconCall} alt="call-icon" />
          </div>
          <div className="meta">
            <span className="meta-title">Call us</span>
            <span className="meta-content">
              <a href="tel:+37066317425">+37066317425</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Contacts
