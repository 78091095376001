import React, { useState, Fragment } from 'react'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps'

import { TitleContainer } from '../pages/Restaurants'
import { FlexDivCentered, FlexButton } from './UI'

// eslint-disable-next-line no-unused-vars
import fbLogo from '../assets/images/icon-fb.svg'
// eslint-disable-next-line no-unused-vars
import instaLogo from '../assets/images/icon-insta.svg'
import priceRange from '../assets/images/price-range-icon.svg'
import restaurantType from '../assets/images/restaurant-type-icon.svg'
import webIcon from '../assets/images/web-icon.png'
import workingHours from '../assets/images/working-hours-icon.svg'
import emailIcon from '../assets/images/email-icon.svg'
import customMarker from '../assets/images/custom-marker.svg'
import arrowDown from '../assets/images/arrow-down.svg'
import arrowDownBlue from '../assets/images/arrow-down-blue.svg'
import arrowUpBlue from '../assets/images/arrow-up-blue.svg'

const InfoContainer = styled(TitleContainer)`
  margin-bottom: 150px;
  display: flex;
  justify-content: space-between;
  transition: all .5s ease-in-out;
  max-height: 0px;
  margin-bottom: 0px;
  opacity: 0;
  pointer-events: none;

  &.info-cards-enter-done {
    pointer-events: auto;
    max-height: 900px;
    margin-bottom: 150px;
    opacity: 1;

    @media screen and (max-width: 1024px) {
      margin-bottom: 100px;
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 25px;
    }
  }

  &.info-cards-exit-done {
    max-height: 0px;
    margin-bottom: 0px;
    opacity: 0;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0px;
  }
`

const Info = styled.div`
  max-width: 545px;
  width: 100%;
  height: auto;
  box-shadow: 0 2px 10px rgba(153, 156, 169, 0.08);
  border-radius: 10px;
  border: 1px solid rgba(153, 156, 169, .2);
  background-color: #ffffff;

  @media screen and (max-width: 767px) {
    width: 100%;
    margin: 0;
    border-radius: 0;
    border: none;
  }
`

const InfoPart = styled.div`
  margin-bottom: ${({ moreMargin, schedule }) => moreMargin ? '32px' : schedule ? '0px' : '22px'};
  display: flex;
  cursor: ${({ schedule }) => schedule ? 'pointer' : null};
  span {
    color: rgba(153, 156, 169, 0.7)
  }

  span.paint {
    color: #000000;
  }

  &:hover {
    color: ${({ hover }) => hover ? '#483BF4' : null};
    div {
      color: ${({ hover }) => hover ? '#483BF4' : null};
    }
  }
`
// eslint-disable-next-line no-unused-vars
const Social = styled(FlexDivCentered)`
  height: 160px;
  padding: 48px 72px 40px 72px;
  border-bottom: 1px solid rgba(153, 156, 169, .2);
  justify-content: space-between;
`
// eslint-disable-next-line no-unused-vars
const IconsContainer = styled(FlexDivCentered)`
  height: 46px;
  width: 106px;
  justify-content: space-between;
`
// eslint-disable-next-line no-unused-vars
const IconSocial = styled.img`
  height: 100%;
`

const GeneralInfo = styled.div`
  padding: 49px 72px 48px 72px;
  color: #000000;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  @media screen and (max-width: 767px) {
    padding: 32px 32px 50px 32px;
  }
`

const Icon = styled.img`
  margin-right: 25px;
`

const Arrow = styled.img`
  margin-left: 8px;
`

const CallButton = styled(FlexButton)`
  background: #483BF4;
  border-radius: 8px;
  height: 55px;
  width: 100%;
  color: #FFFFFF;

  &:hover {
    background: #000000;
  }
`

const MapContainer = styled(Info)`
  height: 552px;
  position: relative;
  border: none;
  box-shadow: none;

  @media screen and (max-width: 767px) {
    height: 470px;
  }
`

const MapDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;

  @media screen and (max-width: 767px) {
    border-radius: 0;
  }
`

const MapInfo = styled.div`
  position: absolute;
  bottom: 16px;
  right: 16px;
  width: 254px;
  box-shadow: 0 2px 15px rgba(153, 156, 169, 0.3);
  border-radius: 8px;
  background-color: #ffffff;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  div {
    color: #000000;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  a {
    color: #483af4;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    width: 122px;
  }
`

const MapLink = styled.a`
  &:hover {
    color: #000000;
  }
`

const ScheduleCardStyled = styled(FlexDivCentered)`
  color: ${({ today }) => today ? '#483BF4' : '#000000'}
  width: 137px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  justify-content: space-between;
  margin-bottom: ${({ margin }) => margin ? '8px' : null};
`

const ScheduleContainer = styled.div`
  padding-left: 46px;
  color: #000000;
  opacity: 0.5;
  margin-bottom: 22px;
  margin-top: 0px;
  height: auto;
  overflow: hidden;
  max-height: 0px;
  transition: max-height .5s ease-in-out;

  &.schedule-enter-done {
    max-height: 230px;
  }

  &.schedule-exit-done {
    margin-top: 0px;
  }
`

const TimesContainer = styled.div`
  padding-top: 7px;
`

const MapComponent = withGoogleMap(({ isMarkerShown, coordinates: { lat, lng } }) => (
  <GoogleMap
    defaultZoom={16}
    center={{ lat: lat, lng: lng }}
    options={{ streetViewControl: false, scrollwheel: false, disableDefaultUI: true }}
  >
    {isMarkerShown && <Marker
                        icon={{
                          url: customMarker,
                          scaledSize: { width: 90, height: 90 },
                          anchor: { x: 45, y: 45 }}}
                        position={{ lat: lat, lng: lng }} />}
  </GoogleMap>
))

const ScheduleCard = ({ d, start, end, ...props }) => (
  <ScheduleCardStyled {...props}>
    {(start && end) ? <Fragment><div>{d}</div><div>{`${start} - ${end}`}</div></Fragment> : <div>Closed</div>}
  </ScheduleCardStyled>
)

const slice = (string) => (string[0].toUpperCase() + string.slice(1)).slice(0, 3)

const restaurantSchedule = (schedules) => {
  let days = {}
  const sorter = {
    "monday": 1,
    "tuesday": 2,
    "wednesday": 3,
    "thursday": 4,
    "friday": 5,
    "saturday": 6,
    "sunday": 7
  }
  const sorted = schedules.sort((a, b) => sorter[a.dayOfWeek] - sorter[b.dayOfWeek])

  sorted.map((obj, index) => {
    return days[index] = {
      d: slice(obj.dayOfWeek),
      start: obj.startTime.slice(0,5),
      end: obj.endTime.slice(0, 5)
    }
  })

  return days
}

const RestaurantInfo = ({ restaurant, ...props }) => {
  const [open, toggleOpen] = useState(false)
  const [hoverArrow, toggleArrowHover] = useState(false)

  const mapOptions = {
    lat: restaurant.latitude,
    lng: restaurant.longitude,
  }
  const today = new Date().getDay() || 7
  const schedule = restaurantSchedule(restaurant.restaurantSchedules)

  return (
    <InfoContainer {...props}>
      <Info>
        {/* <Social>
          Rest logo
          <IconsContainer>
            <IconSocial src={fbLogo} alt="Facebook" />
            <IconSocial src={instaLogo} alt="Instagram" />
          </IconsContainer>
        </Social> */}
        <GeneralInfo>
          <InfoPart>
            <Icon src={priceRange} alt="price-range" />
            {[...Array(4).keys()].map((number, index) => {
              if (index < restaurant.priceRange) {
                return <span key={number} className='paint'>€</span>
              } else {
                return <span key={number}>€</span>
              }
            })}
          </InfoPart>
          <InfoPart>
            <Icon src={restaurantType} alt="rest-type" />
            {restaurant.cuisineType}
          </InfoPart>
          <InfoPart
            hover
            schedule
            onClick={() => toggleOpen(!open)}
            onMouseEnter={() => toggleArrowHover(true)}
            onMouseLeave={() => toggleArrowHover(false)}
          >
            <Icon src={workingHours} alt="working-hours" />
            <ScheduleCard {...schedule[today - 1]} today={open} />
            {open ? <Arrow src={arrowUpBlue} alt="arrow" /> : <Arrow src={hoverArrow ? arrowDownBlue : arrowDown} alt="arrow" />}
          </InfoPart>
          <CSSTransition
            in={open}
            timeout={50}
            classNames='schedule'
          >
            <ScheduleContainer>
              <TimesContainer>
                {restaurant.restaurantSchedules.map((obj, index) => {
                  return <ScheduleCard key={index} {...schedule[index]} margin />
                })}
              </TimesContainer>
            </ScheduleContainer>
          </CSSTransition>
          <InfoPart hover>
            <Icon src={emailIcon} alt="email" />
            <a href={`mailto:${restaurant.email}`}>{restaurant.email}</a>
          </InfoPart>
          {restaurant.linkToRest &&
            <InfoPart hover moreMargin>
              <Icon src={webIcon} alt="web" />
              Link to rest
            </InfoPart>}
          <a href={`tel:[${restaurant.phoneNumber}]`}>
            <CallButton>Call {restaurant.phoneNumber}</CallButton>
          </a>
        </GeneralInfo>
      </Info>
      <MapContainer>
        <MapComponent
          isMarkerShown
          coordinates={mapOptions}
          mapElement={<MapDiv />}
          containerElement={<MapContainer />} />
        <MapInfo>
          <div>{restaurant.displayAddress}</div>
          <MapLink
            rel='noopener noreferrer'
            href={`https://www.google.com/maps/search/?api=1&query=${mapOptions.lat},${mapOptions.lng}`}
            target='_blank'
          >
            Get directions
          </MapLink>
        </MapInfo>
      </MapContainer>
    </InfoContainer>
  )
}

export default RestaurantInfo;
