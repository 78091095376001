import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router'
import { Route, Switch } from 'react-router-dom'

import Header from './components/Header'
import Footer from './components/Footer'
import MobileSearchModal from './components/MobileSearchModal'

import App from './pages/App'
import Restaurants from './pages/Restaurants'
import Restaurant from './pages/Restaurant'
import Contacts from './pages/Contacts'
import PrivacyPolicy from './pages/PrivacyPolicy'
import TermsAndConditions from './pages/TermsAndConditions'
import SelfService from './pages/SelfService'
import PageNotFound from './pages/PageNotFound'
import Tv from './pages/Tv'
import Form from './pages/Form'

class Container extends Component {
  constructor(props) {
    super(props)

    this.redirect = this.redirect.bind(this)
  }

  componentDidMount() {
    window.ga('create', 'UA-133843943-1', 'auto')
    this.redirect()
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      const url = this.buildUrl()

      if (window.ga) {
        this.setPageAndSendToGA(url)
      }
    }
  }

  buildUrl = () => {
    let url = this.props.location.pathname

    if (this.props.location.search.length > 0) {
      url = url + this.props.location.search
    }

    return url
  }

  setPageAndSendToGA = url => {
    window.ga('set', 'page', url)
    window.ga('send', 'pageview')
  }

  redirect() {
    if (window.location.href.indexOf('tableId') > -1) {
      const params = (new URL(window.location.href)).searchParams

      window.location = 'https://dserve.page.link/?link=https://dserve.app?tableId%3D' + params.get('tableId') + '&apn=app.dserve&isi=1435905624&ibi=app.dserve'
    }
  }

  render() {
    const { location } = this.props
    const renderCheck = location.search.includes('app=true') && (location.pathname.includes('terms-and-conditions') || location.pathname.includes('privacy-policy'))
    const tv = location.pathname.includes('tv')

    return (
      <Fragment>
        {renderCheck || tv ? null : <Header />}
        <Switch>
          <Route key='self-service' path='/' exact component={SelfService} />
          <Route key='app' path='/app' component={App} />
          <Route key='restaurants' path='/restaurants' exact component={Restaurants}/>
          <Route key='search-restaurants' path='/restaurants/search' exact component={MobileSearchModal} />
          <Route key='restaurant' path='/restaurants/:restaurantId' component={Restaurant}/>
          <Route key='contacts' path='/contacts' component={Contacts} />
          <Route key='terms-and-conditions' path='/terms-and-conditions' component={TermsAndConditions} />
          <Route key='privacy-policy' path='/privacy-policy' component={PrivacyPolicy} />
          <Route key='page-not-found' path='/page-not-found' component={PageNotFound} />
          <Route key='tv' path='/tv/:restaurantId' component={Tv} />
          <Route key='form' path='/form' component={Form}/>
        </Switch>
        {renderCheck || tv ? null : <Footer />}
      </Fragment>
    )
  }
}

export default withRouter(Container)
