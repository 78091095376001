import React, { Fragment } from 'react'
import styled from 'styled-components'

import { FlexDivCentered } from './UI'
import { titleToSpans } from './RestaurantCard'
import { setLogoSource } from '../utilities/utils'

const Card = styled(FlexDivCentered)`
  border-radius: 10px;
  border: 1px solid rgba(153, 156, 169, 0.2);
  max-width: 545px;
  width: 100%;
  padding: 16px;
  justify-content: space-between;
  height: 100%;
  position: relative;
  align-items: flex-start;
`

const DishImg = styled.img`
  width: 164px;
  height: 164px;
  border-radius: 10px;
`

const DishDescContainer = styled.div`
  padding: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  color: #000000;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  height: auto;
  letter-spacing: 0;
  line-height: 24px;
  width: 100%;
  max-width: ${({ fullLength }) => fullLength ? undefined : '293px'};
  margin-bottom: 12px;

  span.paint {
    color: #483BF4;
  }
`

const Description = styled.div`
  color: #000000;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 20px;
  width: 100%;
  opacity: .5;
  margin-bottom: 16px;
`

const PriceContainer = styled.div`
  display: flex;
  margin-top: auto;
`

const Price = styled.div`
  color: #000000;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  opacity: ${({ regularPrice }) => regularPrice ? 0.5 : 1};
  text-decoration: ${({ regularPrice }) => regularPrice ? 'line-through' : 'none'};
  margin-right: 10px;
`

const DishCard = ({ title, description, price, regularPrice, dishImages, query }) => {
  const displayPrice = `€${price.toFixed(2)}`

  return (
    <Card>
      <DishDescContainer>
        <Title fullLength={dishImages.length === 0}>{titleToSpans(title, query)}</Title>
        <Description>{description}</Description>
        <PriceContainer>
          {regularPrice ? (
            <Fragment>
              <Price>{`€${Math.abs(price.toFixed(2))}`}</Price>
              <Price regularPrice>{`€${Math.abs(regularPrice.toFixed(2))}`}</Price>
            </Fragment>
          ) : (
            <Price>{displayPrice}</Price>
          )}
        </PriceContainer>
      </DishDescContainer>
      {dishImages.length ? <DishImg src={setLogoSource(dishImages[0].imageName, 164, 164)} alt={dishImages[0].imageName} /> : null}
    </Card>
  )
}

export default DishCard
