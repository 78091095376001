import React from 'react'
import styled from 'styled-components'

import { titleToSpans } from './RestaurantCard'
import { setLogoSource } from '../utilities/utils'

const DishCard = styled.div`
  position: relative;
  padding: 22px 32px;
  display: flex;
  justify-content: space-between;
  border-top: ${({ first }) => first ? 'none' : '1px solid rgba(153, 156, 169, .2)'};
`

const DishDescription = styled.div`
  width: calc(100% - 96px);
`

const Title = styled.div`
  color: #000000;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 24px;
  margin-bottom: 8px;
  max-width: 70%;

  span.paint {
    color: #483BF4;
  }
`

const Info = styled.div`
  color: #000000;
  font-size: 13px;
  font-family: Roboto;
  letter-spacing: 0px;
  line-height: 20px;
  opacity: .5;
  padding-right: 20px;
  margin-bottom: 10px;
`

const DishPrice = styled.div`
  color: #000000;
  font-family: Roboto;
  font-size: 14px;
  height: 19px;
  letter-spacing: 0px;
`

const DishImg = styled.img`
  width: 96px;
  height: 96px;
  border-radius: 6px;
`

const MobileDishCard = ({ title, description, price, dishImages, query, first }) => {
  const displayPrice = `€${price.toFixed(2)}`

  return (
    <DishCard first={first}>
      <DishDescription>
        <Title>{titleToSpans(title, query)}</Title>
        <Info>{description}</Info>
        <DishPrice>{displayPrice}</DishPrice>
      </DishDescription>
      {dishImages.length ? <DishImg src={setLogoSource(dishImages[0].imageName, 96, 96)} alt={dishImages[0].imageName} /> : null}
    </DishCard>
  );
};

export default MobileDishCard
