import React, { Component } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'

import g1 from "../assets/images/g-1.jpg"
import g1mobile from "../assets/images/g-1@2x.jpg"

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const SliderStyled = styled(Slider)`
  position: relative;
  width: 100%;

  .slick-dots {
    position: absolute;
    bottom: 0;
    z-index: 3;
    right: 0;
    margin: 48px 47px;

    li {
      float: right;
      padding-left: 13px;
    }

    button {
      width: 8px;
      height: 8px;
      background: #FFFFFF;
      cursor: pointer;
      display: block;
      text-indent: -999em;
      border-radius: 50%;
      opacity: 0.2;
      &::before {
        display: none;
      }

      &:hover {
        opacity: .5;
      }
    }

    .slick-active button {
      opacity: 1;
    }
  }

  @media screen and (max-width: 1024px) {
    .slick-slider {
      .slick-list {
        .slick-track {
          width: 100%;
        }
      }
    }
  }
`


const Image = ({photo, photoMobile, location, restaurantName, type}) => (
  <div className="box">
    <div
      className="bg non-retina"
      style={{ backgroundImage: `url(${photo})` }}
    />
    <div
      className="bg retina"
      style={{ backgroundImage: `url(${photoMobile})` }}
    />
    <div className="text">
      <div className="meta">{location}</div>
      <h4>{restaurantName}</h4>
      <p>{type}</p>
    </div>
  </div>
)

class PhotoSlider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      images: [
        {
          photo: g1,
          photoMobile: g1mobile,
          location: 'Vilnius',
          restaurantName: 'Peri Peri Restas',
          type: 'Family restaurant · Pizza'
        }
      ]
    }
  }

  render() {
    const { ...props } = this.props

    const settings = {
      dots: true,
      arrows: false,
      touchThreshold: 3000,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            arrows: false,
            touchThreshold: 3000,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            arrows: false,
            touchThreshold: 3000,
            centerMode: true
          }
        }
      ]
    }

    return (
      <Container>
        <SliderStyled ref={slider => this.sliderRef = slider} {...settings} {...props}>
          {this.state.images.map((image, index) => <Image key={index} {...image} />)}
        </SliderStyled>
      </Container>
    )
  }
}

export default PhotoSlider
