import React from 'react'
import { hydrate, render } from 'react-dom'
import { BrowserRouter as Router } from "react-router-dom"
import { init } from '@rematch/core'
import { Provider } from 'react-redux'
import createRematchPersist from '@rematch/persist'
import { getPersistor } from '@rematch/persist'
import { PersistGate } from 'redux-persist/lib/integration/react'
import * as Sentry from '@sentry/browser'

import * as models from './redux/models'
import Container from './Container';
import './index.scss'

if (process.env.REACT_APP_ENV) {
  Sentry.init({
    dsn: 'https://1eaec7fcd4de4030b319a4932662312e@sentry.io/1471395',
    environment: process.env.REACT_APP_ENV
  })
}

const redux = { middlewares: [] }

if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`)

  redux.middlewares.push(logger)
}

const persistPlugin = createRematchPersist({
  whitelist: ['restaurants'],
  throttle: 5000,
  version: 1,
})

const store = init({
  models,
  redux,
  plugins: [persistPlugin]
})

const persistor = getPersistor()

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <Container />
      </Router>
    </PersistGate>
  </Provider>
)

const rootElement = document.getElementById('root')

if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement)
} else {
  render(<App />, rootElement)
}
