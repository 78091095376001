import React from 'react'

import p6 from '../assets/images/p6.png'
import p6mobile from '../assets/images/p6@2x.png'
import btnAppStore from '../assets/images/btn-app-store.svg'
import btnGooglePlay from '../assets/images/btn-google-play.svg'

const AdMobile = ({ title, subtitle, noPhoto }) => {
  return (
    <div className='cta-row'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-md-7 col-lg-5'>
            <h2>{title}</h2>
            <p>{subtitle}</p>
            {noPhoto ?
              undefined :
              (
                <div className='shops'>
                  <a
                    rel='noopener noreferrer'
                    href='https://itunes.apple.com/lt/app/dserve/id1435905624'
                    target='_blank'
                  >
                    <img src={btnAppStore} alt='' />
                  </a>
                  <a
                    rel='noopener noreferrer'
                    href='https://play.google.com/store/apps/details?id=app.dserve'
                    target='_blank'
                  >
                    <img src={btnGooglePlay} alt='' />
                  </a>
                </div>
              )
            }
          </div>
          <div className='col-md-5 col-lg-7'>
            {noPhoto ?
              undefined :
              (
                <div className='image'>
                  <img src={p6} srcSet={`${p6mobile} 2x`} alt='' />
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdMobile
