export const replaceLetters = (string) => {
  let newString

  newString = string.replace('č', 'c')
  newString = newString.replace('ę', 'e')
  newString = newString.replace('ė', 'e')
  newString = newString.replace('į', 'i')
  newString = newString.replace('š', 's')
  newString = newString.replace('ų', 'u')
  newString = newString.replace('ū', 'u')
  newString = newString.replace('ž', 'z')

  return newString
}

export const slugify = (string) => replaceLetters(string).toLowerCase().match(new RegExp(/[a-zA-Z0-9]+/g)).join('-')

export const setLogoSource = (imgName, width, height) => {
  let logo = null

  if (imgName) {
    logo = `https://cdn-dserve.imgix.net/${imgName}?w=${width}&h=${height}&fit=crop`
  }

  return logo
}
