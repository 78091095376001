import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import MediaQuery from 'react-responsive'
import { Transition, CSSTransition } from 'react-transition-group'

import SectionHeader from '../components/SectionHeader'
import RestaurantsContainer from '../components/RestaurantsContainer'
import RestaurantCard from '../components/RestaurantCard'
import { FlexDivCentered } from '../components/UI'
import AdMobile from '../components/AdMobile'
// eslint-disable-next-line no-unused-vars
import City from '../components/City'
import NoResultsFound from '../components/NoResultsFound'
import NewSlider from '../components/NewSlider'
import { slugify } from '../utilities/utils'

import hands from '../assets/images/hands.svg'
import searchLogo from '../assets/images/search-logo.svg'
import closeButton from '../assets/images/close-button.svg'

const RestaurantHeader = styled.div`
  position: relative;
  width: 100%;
  height: 360px;
  background: #FFC24D;
  padding: 190px 0 0 0;
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: 767px) {
    height: 197px;
    padding-top: 120px;
  }
`

const HeaderImg = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;

  @media screen and (max-width: 767px) {
    display: none;
  }
`

export const TitleContainer = styled.div`
  max-width: 1196px;
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
  z-index: 8;

  @media screen and (max-width: 767px) {
    margin: 0 32px;
    padding: 0;
  }
`

const Title = styled(FlexDivCentered)`
  color: #000000;
  font-family: Poppins;
  font-size: 48px;
  font-weight: 500;
  height: 67px;
  width: 400px;

  @media screen and (max-width: 767px) {
    font-size: 32px;
    height: 45px;
    width: 311px;
  }
`

export const TopContainer = styled.div`
  border-bottom: 1px solid rgba(153, 156, 169, .2);
  margin-bottom: 96px;

  @media screen and (max-width: 1024px) {
    margin-bottom: 70px;
  }

  @media screen and (max-width: 767px) {
    max-width: 100%;
    width: 100%;
    margin-bottom: 47px;
  }
`

export const SearchBarContainer = styled(FlexDivCentered)`
  max-width: 1196px;
  width: 100%;
  margin: auto;
  padding: 0 30px;
  height: 112px;

  @media screen and (max-width: 767px) {
    box-sizing: border-box;
    max-width: 100%;
    width: 100%;
    padding: 0;
    padding: 0 32px;
    height: 72px;
    justify-content: space-between;
  }
`

export const SearchContainer = styled(FlexDivCentered)`
  height: 40px;
  width: 100%;
  padding-left: ${({ padding }) => padding ? '44px' : null};

  @media screen and (max-width: 767px) {
    justify-content: flex-end;
  }
`

export const SearchLogo = styled.img`
  padding-bottom: 2px;
  margin-right: ${({ noMargin }) => noMargin ? null : '18px'};
  transition: .5s ease-in-out;
  opacity: 1;

  &.photo-enter-done {
    opacity: 0.5;
  }

  &.photo-exit-done {
    opacity: 1;
  }
`

export const ClearTextLogo = styled.img`
  width: 19px;
  height: 19px;
  cursor: pointer;
`

export const Input = styled.input`
  color: #999CA9;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  height: 24px;
  line-height: 24px;
  width: 100%;
  border: none;
  margin-right: 10px;

  &::placeholder {
    color: #999CA9;
  }
`

export const SliderContainer = styled.div`
  transition: opacity .5s ease-in-out;
  opacity: ${({ state }) => (state === "entered" || state === undefined ? 1 : 0)};

  max-width: 1196px;
  width: 100%;
  margin: auto;
  padding: 0 30px;
  height: auto;
  margin-bottom: 140px;
  overflow: hidden;

  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 0px;
    margin-bottom: 60px;
  }
`

export const AllRestaurantsContainer = styled.div`
  max-width: 1196px;
  width: 100%;
  margin: 0 auto 75px auto;
  padding: 0 30px;

  @media screen and (max-width: 767px) {
    padding: ${({ padding }) => padding ? '0 32px': null};
    width: 100%;
    padding: 0;
    margin-bottom: 50px;
  }
`

const NoResultsFoundStyled = styled(NoResultsFound)`
  transition: opacity .5s ease-in-out;
  opacity: ${({ state }) => (state === "entered" ? 1 : 0)};
`

const Restas = styled.div`
  transition: opacity .5s ease-in-out;
  opacity: ${({ state }) => (state ? 1 : 0)};
`

const card = (props) => (
  <Link
    to={{
      pathname: `/restaurants/${slugify(props.name)}`,
      state: {
        restaurantId: props.id
      }
    }}
  >
    <RestaurantCard restaurant={props} />
  </Link>
)

class Restaurants extends Component {
  constructor(props) {
    super(props)

    this.state = {
      query: '',
      clearTextButton: false,
      filteredRestaurants: [],
      mobileSlideWidth: 0,
      hoverSearch: false,
      inputFocused: false
    }

    this.textInput = React.createRef()
    this.clearSearchQuery = this.clearSearchQuery.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.filterRestaurants = this.filterRestaurants.bind(this)
    this.checkQuery = _.debounce(this.checkQuery.bind(this), 500)
    this.enableSeacrhHover = this.enableSeacrhHover.bind(this)
    this.disableSearchHover = this.disableSearchHover.bind(this)
    this.toggleInputFocus = this.toggleInputFocus.bind(this)
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.props.fetchRestaurantsAsync()
  }

  filterRestaurants(list, query) {
    const filtered = list.filter(restaurant => restaurant.name.toLocaleLowerCase().includes(query.toLocaleLowerCase()))
    this.setState(() => ({ filteredRestaurants: filtered, query: query }))
  }

  checkQuery(query) {
    const { list } = this.props.restaurants

    if (query.length === 0) {
      this.setState(() => ({ clearTextButton: false, filteredRestaurants: [], query: '' }))
    } else {
      this.filterRestaurants(list, query)
    }
  }

  handleInputChange(e) {
    const query = e ? e.currentTarget.value : ''
    this.setState(() => ({ clearTextButton: true }))

    this.checkQuery(query)
  }

  clearSearchQuery() {
    this.textInput.current.value = ''
    this.setState({ clearTextButton: false, query: '', filteredRestaurants: [] })
  }

  enableSeacrhHover() {
    this.setState(({ inputFocused }) => ({ hoverSearch: inputFocused ? false : true }))
  }

  disableSearchHover() {
    this.setState((state) => ({ hoverSearch: false }))
  }

  toggleInputFocus() {
    this.setState(({ inputFocused }) => ({ inputFocused: !inputFocused, hoverSearch: false }))
  }

  render() {
    const { enableSeacrhHover, disableSearchHover, clearSearchQuery, toggleInputFocus, handleInputChange, textInput } = this
    const { restaurants } = this.props
    const { query, filteredRestaurants, mobileSlideWidth, hoverSearch } = this.state
    const noQuery = filteredRestaurants.length === 0 && query.length === 0
    const zeroResults = filteredRestaurants.length === 0 && query.length > 0
    const val = this.textInput.current ? this.textInput.current.value : ''

    return (
      <div className="body">
        <RestaurantHeader>
          <TitleContainer>
            <Title>Restaurants</Title>
          </TitleContainer>
          <HeaderImg src={hands} alt="hands" />
        </RestaurantHeader>

        <TopContainer>
          <SearchBarContainer>
            {/* <City getRestaurants={this.props.fetchRestaurantsAsync} /> */}
            <SearchContainer onMouseEnter={enableSeacrhHover} onMouseLeave={disableSearchHover}>
              <MediaQuery maxWidth={767}>
                {(matches) => {
                  if (matches) {
                    return (
                      <Link to={`/restaurants/search`}>
                        <SearchLogo noMargin src={searchLogo} alt="search" />
                      </Link>
                    )
                  } else {
                    return (
                      <Fragment>
                        <CSSTransition in={hoverSearch} timeout={100} classNames="photo">
                          <SearchLogo src={searchLogo} alt="search"/>
                        </CSSTransition>
                        <Input
                          placeholder={'Search restaurants'}
                          onChange={handleInputChange}
                          onFocus={toggleInputFocus}
                          onBlur={toggleInputFocus}
                          ref={textInput}
                          value={val} />
                        {this.state.clearTextButton ? <ClearTextLogo src={closeButton} alt="close-button" onClick={clearSearchQuery} /> : null}
                      </Fragment>
                    )
                  }
                }}
              </MediaQuery>
            </SearchContainer>
          </SearchBarContainer>
        </TopContainer>

        <Transition in={zeroResults} timeout={400} unmountOnExit mountOnEnter>
          {(state) => <NoResultsFoundStyled state={state} query={query} />}
        </Transition>

        <Transition in={noQuery} timeout={200} unmountOnExit mountOnEnter>
          {(state) => (
            <SliderContainer state={state}>
              <NewSlider
                slideWidth={mobileSlideWidth}
                list={restaurants.list}
                title={'Trending right now'}
                template={card}
                displayCount={3}
              />
            </SliderContainer>
          )}
        </Transition>

        <AllRestaurantsContainer>
          <Transition in={!zeroResults && !noQuery} timeout={600} unmountOnExit mountOnEnter>
            {(state) => (
              <Restas state={state === 'entered' && !!val.length}>
                <RestaurantsContainer restaurants={filteredRestaurants} query={query} />
              </Restas>
            )}
          </Transition>

          {noQuery ?
            [
              <SectionHeader key='title' title={'All restaurants'} count={restaurants.list.length} />,
              <RestaurantsContainer key='list' restaurants={restaurants.list} />
            ] :
            null
          }
        </AllRestaurantsContainer>
        <AdMobile
          title={'Start ordering now'}
          subtitle={'Downloading and using DSERVE is easy - choosing where to eat is the hard part!'} />
      </div>
    )
  }
}

export default connect(
  ({ restaurants }) => ({ restaurants }),
  ({ restaurants: { fetchRestaurantsAsync } }) => ({ fetchRestaurantsAsync })
)(Restaurants);
