import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { withRouter, Link, Route } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import MediaQuery from 'react-responsive'

import Menu from '../components/Menu'
import RestaurantInfo from '../components/RestaurantInfo'
import { FlexButton, FlexDivCentered } from '../components/UI'
import {
  TitleContainer,
  TopContainer,
  SearchBarContainer,
  SearchContainer,
  SearchLogo,
  Input,
  ClearTextLogo
} from './Restaurants'
import AdMobile from '../components/AdMobile'
import DishesContainer from '../components/DishesContainer'
import NoResultsFound from '../components/NoResultsFound'
import MobileSearchModal from '../components/MobileSearchModal'
import { slugify, setLogoSource } from '../utilities/utils'

import searchLogo from '../assets/images/search-logo.svg'
import arrowDown from '../assets/images/arrow-down.svg'
import whiteStar from '../assets/images/white-star.svg'
import arrowBack from '../assets/images/white-arrow-back.svg'
import arrowUp from '../assets/images/arrow-up.svg'
import closeButton from '../assets/images/close-button.svg'
import Helmet from 'react-helmet'

const RestaurantHeader = styled.div`
  width: 100%;
  height: 480px;
  background-size: cover;
  padding: 130px 0 0 0;
  display: flex;
  align-items: flex-start;
  position: relative;

  &::after {
    content: "";
    background: #FFC24D url(${({ background }) => background}) no-repeat;
    background-size: cover;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -3;
  }

  &::before {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    background: rgba(46, 46, 46, 0.5);
    z-index: -2;
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 767px) {
    height: 340px;
  }
`

const RestaurantReviewContainer = styled.div`
  width: auto;
  height: auto;
  position: absolute;
  bottom: 32px;
  right: 32px;
  display: flex;

  @media screen and (max-width: 767px) {
    bottom: 16px;
    right: 16px;
  }
`

const Rating = styled(FlexButton)`
  background: #f9ba43;
  color: #FFFFFF;
  border-radius: 6px;
  height: 56px;
  width: 76px;
  margin-right: 18px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;

  @media screen and (max-width: 767px) {
    height: 40px;
    width: 58px;
    margin-right: 0px;
    font-size: 12px;
  }
`

// eslint-disable-next-line no-unused-vars
const PhotosButton = styled(FlexButton)`
  background: #FFFFFF;
  color: #483AF4;
  border-radius: 8px;
  box-shadow: 0 2px 15px 0 rgba(153, 156, 169, 0.3);
  height: 56px;
  width: 164px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`

const ReturnDiv = styled.div`
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  height: 24px;
  line-height: 24px;
  width: 120px;
  vertical-align: middle;
  margin-bottom: 85px;
  cursor: pointer;
`

const Title = styled(FlexDivCentered)`
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 48px;
  font-weight: 500;
  height: 134px;
  line-height: normal;
  width: 570px;

  @media screen and (max-width: 767px) {
    font-size: 32px;
    line-height: 40px;
    width: 100%;
    height: auto;
  }
`

const TopContainerStyled = styled(TopContainer)`
  @media screen and (max-width: 767px) {
    margin-bottom: 0px;
  }
`

const MoreInfoContainer = styled(FlexDivCentered)`
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  height: 40px;
  padding-right: 44px;
  border-right: 1px solid rgba(153, 156, 169, .2);
  transition: .5s ease-in-out;

  &:hover {
    opacity: 0.5;
  }

  @media screen and (max-width: 767px) {
    border: none;
  }
`
const MoreInfo = styled.div`
  cursor: pointer;
  width: 92px;
`

const MenuContainer = styled.div`
  max-width: 1196px;
  width: 100%;
  margin: 0 auto 20px auto;
  padding: 0 30px;

  @media screen and (max-width: 767px) {
    margin: 0;
    padding: 40px 0;
    width: 100%;
  }
`

const MoreInfoArrow = styled.img`
  margin-left: 5px;
`

const WhiteStart = styled.img`
  padding-bottom: 3px;
  margin-right: 6px;

  @media screen and (max-width: 767px) {
    width: 9px;
    height: 9px;
    padding-bottom: 1px;
  }
`

export const ArrowBack = styled.img`
  margin-right: 14px;
  padding-bottom: 2px;
`

class Restaurant extends Component {
  constructor(props) {
    super(props);

    this.state = {
      infoOpen: false,
      filteredDishes: [],
      clearTextButton: false,
      query: '',
      hoverSearch: false,
      inputFocused: false
    }

    this.toggleInfoContainer = this.toggleInfoContainer.bind(this)
    this.clearRestaurantInfo = this.clearRestaurantInfo.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.filterDishes = this.filterDishes.bind(this)
    this.clearSearchQuery = this.clearSearchQuery.bind(this)
    this.enableSeacrhHover = this.enableSeacrhHover.bind(this)
    this.disableSearchHover = this.disableSearchHover.bind(this)
    this.toggleInputFocus = this.toggleInputFocus.bind(this)
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const {
      location,
      match,
      history,
      restaurants,
      fetchRestaurantMenuAsync,
      fetchRestaurantAsync,
      fetchRestaurantsAsync,
      setActiveRestaurant
    } = this.props
    let id = location.state ? location.state.restaurantId : undefined

    if (id) {
      fetchRestaurantMenuAsync(id)
      fetchRestaurantAsync(id)
    }

    if (!location.state) {
      if (!restaurants.list.length) {
        return fetchRestaurantsAsync()
      } else {
        const restaurant = this.findRestaurant(match.params.restaurantId)

        if (restaurant) {
          setActiveRestaurant(restaurant.id)

          return history.replace(
            `/restaurants/${slugify(restaurant.name)}`,
            { restaurantId: restaurant.id }
          )
        } else {
          return history.replace(`/page-not-found`)
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      location,
      match,
      restaurants,
      fetchRestaurantMenuAsync,
      fetchRestaurantAsync
    } = this.props

    if (this.props.match.params !== prevProps.match.params) {
      window.scrollTo(0, 0)
      let id = location.state ? location.state.restaurantId : undefined

      if (!id) {
        const slugId = match.params.restaurantId

        id = restaurants.list.find(rest => slugify(rest.name) === slugId).id
      }

      fetchRestaurantMenuAsync(id)
      fetchRestaurantAsync(id)
    }

    if (this.props.restaurants.list.length !== prevProps.restaurants.list.length) {
      const restaurant = this.findRestaurant(match.params.restaurantId)

      if (restaurant) {
        fetchRestaurantMenuAsync(restaurant.id)
        fetchRestaurantAsync(restaurant.id)
      }
    }
  }

  findRestaurant(id) {
    const { list } = this.props.restaurants
    let restaurant = list.find(rest => slugify(rest.name) === id)

    if (restaurant) return restaurant

    restaurant = list.find(rest => rest.id === id)

    return restaurant
  }

  clearRestaurantInfo() {
    this.props.setActiveRestaurant(null)
    this.props.setRestaurantInfo({})
  }

  toggleInfoContainer() {
    this.setState(({ infoOpen }) => ({ infoOpen: !infoOpen }))
  }

  handleInputChange(e) {
    const query = e.currentTarget.value
    const { dishes } = this.props.restaurantMenu.menu
    this.setState({ query: query, clearTextButton: true, infoOpen: false })

    if (query.length === 0) {
      this.setState({ clearTextButton: false, filteredDishes: [] })
    } else {
      this.filterDishes(dishes, query)
    }
  }

  filterDishes(list, query) {
    const filtered = list.filter(dish => dish.title.toLocaleLowerCase().includes(query.toLocaleLowerCase()))
    this.setState(() => ({ filteredDishes: filtered }))
  }

  clearSearchQuery() {
    this.setState({ clearTextButton: false, query: '', filteredDishes: [] })
  }

  enableSeacrhHover() {
    this.setState(({ inputFocused }) => ({ hoverSearch: inputFocused ? false : true }))
  }

  disableSearchHover() {
    this.setState((state) => ({ hoverSearch: false }))
  }

  toggleInputFocus() {
    this.setState(({ inputFocused }) => ({ inputFocused: !inputFocused, hoverSearch: false }))
  }

  render() {
    const {
      enableSeacrhHover,
      disableSearchHover,
      clearSearchQuery,
      handleInputChange,
      toggleInfoContainer,
      clearRestaurantInfo,
      toggleInputFocus
    } = this
    const { infoOpen, filteredDishes, query, clearTextButton, hoverSearch } = this.state
    const { restaurantInfo: restaurant } = this.props.restaurants
    const queryPresent = !(filteredDishes.length === 0 && query.length === 0)
    const zeroResults = filteredDishes.length === 0 && query.length > 0

    if (restaurant.id) {
      const ldjson = Object.assign({
        "@context": "http://schema.org",
        "@type": "Restaurant", "url": window.location.href
      },
        !restaurant.description ? null : { description: restaurant.description },
        !restaurant.logoImageName ? null : { logo: `https://cdn-dserve.imgix.net/${restaurant.logoImageName}` },
        !restaurant.name ? null : { name: restaurant.name },
        !restaurant.displayAddress ? null : { address: { "@type": 'PostalAddress', streetAddress: restaurant.displayAddress } },
        !restaurant.rating && !restaurant.votesCount ? null : {
          aggregateRating: {
            "@type": "AggregateRating",
            "ratingValue": restaurant.rating,
            "reviewCount": restaurant.votesCount,
            "bestRating": 5,
            "worstRating": 1
          }
        },
        !restaurant.imageName ? null : {
          image: `https://cdn-dserve.imgix.net/${restaurant.imageName}`
        },
        !restaurant.webPage ? null : {
          sameAs: restaurant.webPage
        },
        !restaurant.phoneNumber ? null : {
          telephone: restaurant.phoneNumber
        },
        !restaurant.restaurantSchedules ? null : {
          "openingHours": [restaurant.restaurantSchedules.map(day => `${day.dayOfWeek.substring(0, 2).charAt(0).toUpperCase()}${day.dayOfWeek.slice(1, 2)} ${day.startTime.substring(0, 5)}-${day.endTime.substring(0, 5)}`)]
        },
        !restaurant.longitude && !restaurant.latitude ? null : {
          "geo": {
            "@type": 'GeoCoordinates',
            longitude: restaurant.longitude,
            latitude: restaurant.latitude
          }
        },
        !restaurant.cuisineType ? null : {
          servesCuisine: restaurant.cuisineType
        },
      )
      const stringified = JSON.stringify(ldjson);
      return (
        <div className="body">
          <Helmet>
            <title>{ldjson.name}</title>
            <meta
              name='description'
              description={ldjson.description} />
            <script type="application/ld+json">
              {`${stringified}`}
            </script>
          </Helmet>
          <RestaurantHeader background={setLogoSource(restaurant.imageName, 1500, 480)}>
            <TitleContainer>
              <MediaQuery maxWidth={767}>
                {(matches) => {
                  if (matches) {
                    return <Title>{restaurant.name}</Title>
                  } else {
                    return (
                      <Fragment>
                        <ReturnDiv onClick={clearRestaurantInfo}>
                          <Link to="/restaurants">
                            <ArrowBack src={arrowBack} alt="" />
                            Go back
                          </Link>
                        </ReturnDiv>
                        <Title>{restaurant.name}</Title>
                      </Fragment>
                    )
                  }
                }}
              </MediaQuery>
            </TitleContainer>
            <RestaurantReviewContainer>
              {restaurant.rating > 0 && (
                <Rating>
                  <WhiteStart src={whiteStar} alt="" />
                  {restaurant.rating}
                </Rating>
              )}
              {/* <PhotosButton>View photos</PhotosButton> */}
            </RestaurantReviewContainer>
          </RestaurantHeader>

          <TopContainerStyled>
            <SearchBarContainer>
              <MediaQuery maxWidth={767}>
                {(matches) => {
                  if (matches) {
                    return (
                      <Fragment>
                        <MoreInfoContainer>
                          <MoreInfo onClick={toggleInfoContainer}>
                            More info
                            <MoreInfoArrow src={infoOpen ? arrowUp : arrowDown} alt="Info" />
                          </MoreInfo>
                        </MoreInfoContainer>
                        <Link to={`/restaurants/${restaurant.id}/search`}>
                          <SearchLogo noMargin src={searchLogo} alt="search" />
                        </Link>
                      </Fragment>
                    )
                  } else {
                    return (
                      <Fragment>
                        <MoreInfoContainer>
                          <MoreInfo onClick={toggleInfoContainer}>
                            More info
                            <MoreInfoArrow src={infoOpen ? arrowUp : arrowDown} alt="Info" />
                          </MoreInfo>
                        </MoreInfoContainer>
                        <SearchContainer padding onMouseEnter={enableSeacrhHover} onMouseLeave={disableSearchHover}>
                          <CSSTransition in={hoverSearch} timeout={100} classNames="photo">
                            <SearchLogo src={searchLogo} alt="search" />
                          </CSSTransition>
                          <Input
                            placeholder={'Search meals'}
                            onFocus={toggleInputFocus}
                            onBlur={toggleInputFocus}
                            onChange={handleInputChange}
                            value={query} />
                          {clearTextButton ? <ClearTextLogo src={closeButton} alt="close-button" onClick={clearSearchQuery} /> : null}
                        </SearchContainer>
                      </Fragment>
                    )
                  }
                }}
              </MediaQuery>
            </SearchBarContainer>
          </TopContainerStyled>
          <CSSTransition
            in={infoOpen}
            timeout={100}
            classNames='info-cards'
          >
            <RestaurantInfo restaurant={restaurant} />
          </CSSTransition>
          <MenuContainer>
            {!queryPresent && <Menu menu={this.props.restaurantMenu.menu} />}
            <CSSTransition
              in={zeroResults && queryPresent}
              timeout={100}
              classNames='no-results'
              unmountOnExit
              mountOnEnter
            >
              <NoResultsFound query={query} />
            </CSSTransition>
            <CSSTransition
              in={!zeroResults && queryPresent}
              timeout={100}
              classNames='query-dishes'
              unmountOnExit
              mountOnEnter
            >
              <DishesContainer list={filteredDishes} query={query} />
            </CSSTransition>
          </MenuContainer>
          <AdMobile
            title={'Start ordering now'}
            subtitle={'Downloading and using DSERVE is easy - choosing where to eat is the hard part!'} />

          <Route key='search-meals' path='/restaurants/:restaurantId/search' component={MobileSearchModal} />

        </div>
      )
    } else {
      return null
    }
  }
}

export default connect(
  ({ restaurantMenu, restaurants }) => ({ restaurantMenu, restaurants }),
  ({
    restaurantMenu: { fetchRestaurantMenuAsync },
    restaurants: { setActiveRestaurant, fetchRestaurantAsync, setRestaurantInfo, fetchRestaurantsAsync }
  }) => ({ fetchRestaurantMenuAsync, setActiveRestaurant, fetchRestaurantAsync, setRestaurantInfo, fetchRestaurantsAsync })
)(withRouter(Restaurant))
