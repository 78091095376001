import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import StiprusKartuBanner from '../assets/images/stipruskartu-banner.png'

const Header = ({ restaurants, location, ...props }) => {
  const stateCheck = location.state && location.state.restaurantId
  const headerCheck = stateCheck && location.state.restaurantId.includes(restaurants.activeRestaurant) ?
    'active-restaurant' :
    null
  const logo = headerCheck ? 'logo-white' : 'logo'
  const closeMenu = () => {
    props.setActiveRestaurant(null)
    document.querySelector("body").classList.toggle("active-menu")
    return
  }
  const linkClick = () => {
    props.setActiveRestaurant(null)
    return
  }

  return (
    <header className="header">
      <div className="container">
        <a href="/" className={logo}>dserve</a>
        <nav className="main-nav">
          <ul className={headerCheck}>
            <li onClick={closeMenu}><NavLink to="/form" exact><img src={StiprusKartuBanner} alt="strong together"/></NavLink></li>
            <li onClick={closeMenu}><NavLink to="/" exact>Self-service</NavLink></li>
            <li onClick={closeMenu}><NavLink to="/app" exact>App</NavLink></li>
            <li onClick={closeMenu}><NavLink to="/restaurants">Restaurants</NavLink></li>
            <li onClick={closeMenu}><NavLink to="/contacts">Contacts</NavLink></li>
          </ul>
        </nav>
        <div className={`${headerCheck} mobile-link`}>
          <ul>
            <li onClick={linkClick}><NavLink to="/form" exact><img src={StiprusKartuBanner} alt="strong together"/></NavLink></li>
            {location.pathname === '/' && <li onClick={linkClick}><NavLink to="/app" exact>App</NavLink></li>}
            {location.pathname === '/app' && <li onClick={linkClick}><NavLink to="/" exact>Self-service</NavLink></li>}
          </ul>
        </div>
        <div active={headerCheck} className={`menu-trigger ${headerCheck}`} onClick={() => {
          document.querySelector("body").classList.toggle("active-menu")
        }}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </header>
  )
}

export default connect(
  ({ restaurants }) => ({ restaurants }),
  ({ restaurants: { setActiveRestaurant } }) => ({ setActiveRestaurant })
)(withRouter(Header))
