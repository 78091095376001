import React from 'react'
import styled from 'styled-components'
import { Link, withRouter } from 'react-router-dom'
import MediaQuery from 'react-responsive'

import RestaurantCard from './RestaurantCard'
import { slugify } from '../utilities/utils'

const CardsContainer = styled.div`
  width: 100%;

  @media screen and (max-width: 767px) {
    padding: 0 32px;
    display: flex;
    flex-direction: column;
    align-content: center;
  }
`

const Row = styled.div`
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
`

const Col = styled.div`
  box-sizing: border-box;
  flex: 0 0 auto;
  flex-basis: 0;
  flex-grow: 1;
  flex-basis: ${({ col }) => col / 12 * 100}%;
  max-width: ${({ col }) => col / 12 * 100}%;
  padding-left: 20px;
  padding-right: 20px;
`

const Card = ({ restaurant, query }) => (
  <Link
    to={{
      pathname: `/restaurants/${slugify(restaurant.name)}`,
      state: {
        restaurantId: restaurant.id
      }
    }}
  >
    <RestaurantCard margin restaurant={restaurant} query={query} />
  </Link>
)
const columns = window.innerWidth > 1024 ? 4 : 6

const RestaurantsContainer = ({ restaurants, query, ...props }) => {
  return (
    <CardsContainer {...props}>
      <MediaQuery maxWidth={767}>
        {(matches) => {
          if (matches) {
            return restaurants.map(restaurant => <Card restaurant={restaurant} key={restaurant.id} query={query} {...props} />)
          } else {
            return (
              <Row>
                {restaurants.map(restaurant => {
                  return (
                    <Col key={restaurant.id} col={`${columns}`}>
                      <Card restaurant={restaurant} query={query} {...props} />
                    </Col>
                  )
                })}
              </Row>
            )
          }
        }}
      </MediaQuery>
    </CardsContainer>
  )
}

export default withRouter(RestaurantsContainer)
