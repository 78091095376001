const baseUrl = `${process.env.REACT_APP_API_URL}/api`

export const json = response => response.json()

export const handleResponseByStatus = async response => {
  if (!response.ok) {
    if (response.status === 400) {
      return Promise.reject(await response.json())
    }

    return Promise.reject(response)
  }

  return Promise.resolve(response)
}

class Api {
  fetchRestaurants({ lat, lng } = {}) {
    const url = new URL(`${baseUrl}/restaurants`)

    if (lat && lng) {
      url.searchParams.append('latitude', lat)
      url.searchParams.append('longitude', lng)
    }

    return fetch(url)
      .catch(e => e)
      .then(handleResponseByStatus)
      .then(json)
  }

  fetchRestaurantInfo(restaurantId) {
    return fetch(`${baseUrl}/restaurants/${restaurantId}`)
      .catch(e => e)
      .then(handleResponseByStatus)
      .then(json)
  }

  fetchRestaurantMenu(restaurantId) {
    return fetch(`${baseUrl}/restaurants/${restaurantId}/menu`)
      .catch(e => e)
      .then(handleResponseByStatus)
      .then(json)
  }
}

export default new Api()
