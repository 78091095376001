import React from 'react'
import styled from 'styled-components'

import { FlexDivCentered } from '../components/UI'

const SectionTitle = styled(FlexDivCentered)`
  color: #000000;
  font-family: Poppins;
  font-size: 26px;
  font-weight: 500;
  height: 36px;
  width: 500px;
  margin: 8px auto 56px 0;

  @media screen and (max-width: 767px) {
    font-size: 22px;
    width: 320px;
    height: 31px;
    margin: 4px auto 32px 32px;
  }
`

const SectionHeader = ({ title, count }) => {
  return (
    <SectionTitle>{title} {count ? `(${count})` : null}</SectionTitle>
  )
}

export default SectionHeader
