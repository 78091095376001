import React, { Component } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { TopContainer, SearchLogo, Input, ClearTextLogo } from '../pages/Restaurants'
import { FlexDivCentered } from './UI'
import MobileDishCard from './MobileDishCard'
import RestaurantsContainer from './RestaurantsContainer'
import { AllRestaurantsContainer } from '../pages/Restaurants'
import NoResultsFound from './NoResultsFound'


import arrowBack from '../assets/images/icon-back-24.png'
import searchLogo from '../assets/images/search-logo.svg'
import closeButton from '../assets/images/close-button.svg'

const Container = styled.div`
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 20;
  background: #FFF;
  overflow: scroll;
`

const ListContainer = styled.div`
  width: 100%;
`

const TopContainerStyled = styled(TopContainer)`
  margin-bottom: 25px;
  height: 152px;
`

const ReturnDiv = styled.div`
  width: 100%;
  height: 24px;
  padding: 33px 32px;
  color: #000000;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`

const SearchContainer = styled(FlexDivCentered)`
  padding: 27px 32px;
  box-sizing: border-box;
  justify-content: space-between;
`

const ArrowBack = styled.img`
  padding-bottom: 4px;
`

const ObjList = ({ type, filteredObjects: list, query, ...props }) => {
  if (type === 'dishes') {
    return (
      list.map((dish, index) => {
        return (
          <MobileDishCard key={dish.id} {...dish} query={query} first={index === 0} />
        )
      })
    )
  } else {
    return (
      <AllRestaurantsContainer padding>
        <RestaurantsContainer restaurants={list} query={query} />
      </AllRestaurantsContainer>
    )
  }
}

class MobileSearchModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: '',
      query: '',
      clearTextButton: false,
      filteredObjects: [],
      list: []
    }

    this.clearSearchQuery = this.clearSearchQuery.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.goBack = this.goBack.bind(this)
    this.filterList = this.filterList.bind(this)
  }

  componentDidMount() {
    if (this.props.match.url === '/restaurants/search') {
      this.setState((state) => ({ list: this.props.restaurants.list, type: 'restaurants' }))
    } else {
      this.setState((state) => ({ list: this.props.restaurantMenu.menu.dishes, type: 'dishes' }))
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      if (this.props.match.url === '/restaurants/search') {
        this.setState((state) => ({ list: this.props.restaurants.list, type: 'restaurants' }))
      } else {
        this.setState((state) => ({ list: this.props.restaurantMenu.menu.dishes, type: 'dishes' }))
      }
    }
  }

  componentWillMount() {
    document.querySelector('body').classList.add('body-block')
  }

  componentWillUnmount() {
    document.querySelector('body').classList.remove('body-block')
  }

  clearSearchQuery() {
    this.setState({ clearTextButton: false, query: '', filteredDishes: [] })
  }

  goBack() {
    this.props.history.goBack();
  }

  filterList(list, query, type) {
    let filtered = []

    if (type === 'dishes') {
      filtered = list.filter(obj => obj.title.toLocaleLowerCase().includes(query.toLocaleLowerCase()))
    } else {
      filtered = list.filter(obj => obj.name.toLocaleLowerCase().includes(query.toLocaleLowerCase()))
    }

    this.setState(() => ({ filteredObjects: filtered }))
  }

  handleInputChange(e) {
    const query = e.currentTarget.value
    const { list, type } = this.state

    if (query.length === 0) {
      this.setState({ clearTextButton: false, filteredObjects: [], query: query })
    } else {
      this.setState({ query: query, clearTextButton: true })
      this.filterList(list, query, type)
    }
  }

  render() {
    const { clearTextButton, filteredObjects, query } = this.state
    const resultCheck = filteredObjects.length === 0 && query.length > 0
    const queryCheck = filteredObjects.length === 0 && query.length === 0
    const placeholderCheck = this.props.match.path === '/restaurants/search'

    return (
      <Container {...this.props}>
        <TopContainerStyled>
          <ReturnDiv onClick={this.goBack}>
            <ArrowBack src={arrowBack} alt="back"/>
            Go back
          </ReturnDiv>
          <SearchContainer>
            <SearchLogo src={searchLogo} alt="search" />
            <Input placeholder={placeholderCheck ? 'Search restaurants' : 'Search meals'} onChange={this.handleInputChange} value={query} />
            {clearTextButton ? <ClearTextLogo src={closeButton} alt="close-button" onClick={this.clearSearchQuery} /> : null}
          </SearchContainer>
        </TopContainerStyled>
        <ListContainer>
          {queryCheck ?
            null :
            resultCheck ?
              <NoResultsFound query={query} /> :
              <ObjList {...this.state} />
          }
        </ListContainer>
      </Container>
    )
  }
}

export default connect(
  ({ restaurantMenu, restaurants }) => ({ restaurantMenu, restaurants }),
  {}
)(withRouter(MobileSearchModal))
