import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { CSSTransition } from 'react-transition-group'

import { FlexButton, FlexDivCentered } from '../components/UI'
import star from '../assets/images/star.svg'
import { setLogoSource } from '../utilities/utils'

const Card = styled.div`
  position: relative;
  width: 350px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${({ margin }) => margin ? '72px' : null};

  @media screen and (max-width: 767px) {
    width: calc(100vw - 64px);
    margin-bottom: ${({ margin }) => margin ? '44px' : null}
  }
`

const ImgContainer = styled.div`
  width: 100%;
  height: 264px;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 24px;

  @media screen and (max-width: 767px) {
    height: 235px;
    margin-bottom: 14px;
  }
`

const CardImg = styled.img`
  width: 100%;
  height: 264px;
  border-radius: 8px;
  transition: transform .5s ease-in-out;
  transform-origin: center;

  &.photo-hover-enter-done {
    transform: scale(1.2);
  }

  &.photo-hover-exit-done {
    transform: scale(1);
  }


  @media screen and (max-width: 767px) {
    height: 235px;
  }
`

const RatingStar = styled.img`
  margin-right: 6px;
  padding-bottom: 1px;

  @media screen and (max-width: 767px) {
    width: 7px;
    height: 7px;
  }
`

const CardTitle = styled.div`
  color: #000000;
  font-family: Poppins;
  font-size: 22px;
  font-weight: normal;
  letter-spacing: -0.3px;
  line-height: 28px;
  width: 100%;
  margin-bottom: 12px;

  span.paint {
    color: #483BF4;
  }

  @media screen and (max-width: 767px) {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.2px;
    margin-bottom: 8px;
  }
`

const CardSubtitle = styled(FlexDivCentered)`
  color: #999CA9;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  height: auto;
  letter-spacing: 0;
  line-height: 20px;
`

const SubSpan = styled.div`
  min-width: ${({ minWidth }) => minWidth ? `${9 * minWidth}px` : undefined};
  margin-left: ${({ left }) => left ? '0px' : '8px'};
  margin-right: ${({ right }) => right ? '0px' : '8px'};
`

const Rating = styled.div`
  color: #000000;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  height: 24px;
  width: 24px;

  @media screen and (max-width: 767px) {
    font-size: 12px;
    height: 17px;
    line-height: 17px;
    width: 18px;
  }
`

const RestaurantRating = styled(FlexButton)`
  z-index: 5;
  position: absolute;
  top: 16px;
  right: 16px;
  background: #FFFFFF;
  border-radius: 8px;
  height: 56px;
  width: 76px;

  @media screen and (max-width: 767px) {
    height: 40px;
    width: 58px;
    border-radius: 6px;
  }
`

const Message = styled(FlexDivCentered)`
  width: 100%;
  height: 264px;
  border-radius: 8px;
  z-index: 4;
  position: absolute;
  top: 0;
  left: 0;
  color: #FFFFFF;
  background: rgba(0, 0, 0, 0.4);
  justify-content: center;
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  transition: opacity .5s ease-in-out;
  opacity: 0;

  &.photo-hover-enter-done {
    opacity: 1;
  }

  &.photo-hover-exit-done {
    opacity: 0;
  }

  @media screen and (max-width: 1024px) {
    height: 270px;
  }

  @media screen and (max-width: 767px) {
    height: 235px;
  }
`

export const titleToSpans = (title, query) => {
  if (query) {
    const startIndex = title.toLowerCase().indexOf(query.toLowerCase())
    if (startIndex < 0) {
      return title
    }
    const endIndex = startIndex + query.length - 1

    return title.split('').map((letter, index) => {
      if (index >= startIndex && index <= endIndex) {
        return <span key={index} className='paint'>{letter}</span>
      } else {
        return <span key={index}>{letter}</span>
      }
    })
  } else {
    return title
  }
}

class RestaurantCard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hover: false,
      focus: false
    }

    this.toggleMessage = this.toggleMessage.bind(this)
    this.setActiveRestaurant = this.setActiveRestaurant.bind(this)
    this.checkWidth = this.checkWidth.bind(this)
  }

  toggleMessage() {
    this.setState(({ hover }) => ({ hover: !hover }))
  }

  setActiveRestaurant() {
    const { restaurant } = this.props
    this.props.setActiveRestaurant(restaurant.id)
  }

  checkWidth() {
    if (window.innerWidth < 768) {
      return window.innerWidth
    } else {
      return 350
    }
  }

  render() {
    const { restaurant, query, ...props } = this.props
    const { hover } = this.state
    const width = this.checkWidth()

    return (
      <Card
        {...props}
        slideWidth={restaurant.slideWidth}
        onMouseEnter={this.toggleMessage}
        onMouseLeave={this.toggleMessage}
        onClick={this.setActiveRestaurant}
      >
        <CSSTransition
          in={hover}
          timeout={100}
          classNames='photo-hover'
        >
          <Message>View restaurant</Message>
        </CSSTransition>
        {restaurant.rating > 0 && (
          <RestaurantRating>
            <RatingStar src={star} alt="star" />
            <Rating>{restaurant.rating}</Rating>
          </RestaurantRating>
        )}
        <ImgContainer>
          <CSSTransition
            in={hover}
            timeout={100}
            classNames='photo-hover'
          >
            <CardImg src={`${setLogoSource(restaurant.imageName, width, 264)}`} alt={`${restaurant.name}`} />
          </CSSTransition>
        </ImgContainer>
        <CardTitle>{titleToSpans(restaurant.name, query)}</CardTitle>
        <CardSubtitle>
          {restaurant.priceRange && (
            <Fragment>
              <SubSpan left minWidth={restaurant.priceRange}>{`${'€'.repeat(restaurant.priceRange)}`}</SubSpan>•
            </Fragment>
          )}
          <SubSpan>{restaurant.cuisineType}</SubSpan>
          {restaurant.displayAddress && (
            <Fragment>
              •<SubSpan right>{restaurant.displayAddress}</SubSpan>
            </Fragment>
          )}

        </CardSubtitle>
      </Card>
    )
  }
}

export default connect(() => ({}), ({ restaurants: { setActiveRestaurant } }) => ({ setActiveRestaurant }))(RestaurantCard)
