import React from "react"

import PhotoSlider from "../components/PhotoSlider"
import AppContent from "../components/AppContent"

//Images
import btnAppStore from "../assets/images/btn-app-store.svg"
import btnGooglePlay from "../assets/images/btn-google-play.svg"
import hero from "../assets/images/hero.png"
import heroMobile from "../assets/images/hero@2x.png"
import p2 from "../assets/images/p2.png"
import p2mobile from "../assets/images/p2@2x.png"
import icoIos from "../assets/images/ico-ios.svg"
import icoAndroid from "../assets/images/ico-android.svg"
import p3 from "../assets/images/p3.png"
import p3mobile from "../assets/images/p3@2x.png"
import p4 from "../assets/images/p4.png"
import p4mobile from "../assets/images/p4@2x.png"

const renderListing = () => (
  <div className="interest-row">
    <div className="gallery">
      <PhotoSlider />
    </div>
    <div className="container">
      <div className="row">
        <div className="col-md-5 offset-md-7">
          <h3>Are you a restaurant?</h3>
          <p>
            Streamline your service and attract new dedicated customers by
            getting listed on DSERVE.
            </p>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSdr5ghO5ZP3ULlLl4-ZmtvvDqQ9XsDL7z9qNngKnf4MC0jy4Q/viewform"
            target="_blank"
            className="btn"
            rel="noopener noreferrer"
          >
            Get listed.
            </a>
        </div>
      </div>
    </div>
  </div>
)

const App = () => (
  <AppContent
    titleProps={{
      title: 'Eating out is easier with DSERVE',
      text: 'One app to find a restaurant and order at the table or on the go. Skip the lines and enjoy your meal!',
      src: hero,
      srcMobile: `${heroMobile} 2x`,
      renderAfterText: () => (
        <div className="shops">
          <a
            href="https://itunes.apple.com/lt/app/dserve/id1435905624"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={btnAppStore} alt="iosButton" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=app.dserve"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={btnGooglePlay} alt="googleButton" />
          </a>
        </div>
      )
    }}
    sectionOneProps={{
      title: 'Order food anywhere',
      text: 'Order from your office, home or from the restaurant and enjoy food at the table.',
      src: p2,
      srcMobile: `${p2mobile} 2x`
    }}
    sectionTwoProps={{
      title: 'Try walletless dining experience',
      text: 'Forget unpleasant moments when you wait for a check or awkward situations when you have no coins for tips.',
      src: p3,
      srcMobile: `${p3mobile} 2x`,
      renderAfterText: () => (
        <div className="available">
          Available on:{" "}
          <a rel="noopener noreferrer" href="https://itunes.apple.com/lt/app/dserve/id1435905624" target="_blank">
            <img src={icoIos} alt="" />
          </a>{" "}
          <a rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=app.dserve" target="_blank">
            <img src={icoAndroid} alt="" />
          </a>
        </div>
      )
    }}
    sectionThreeProps={{
      title: 'Enjoy food on your terms',
      text: 'Customise your dishes, see what dishes are available and when - with DSERVE you are in control!',
      src: p4,
      srcMobile: `${p4mobile} 2x`
    }}
    adProps={{
      title: 'Get the service you DSERVE',
      text: 'Downloading and using DSERVE is easy - choosing where to eat is the hard part!'
    }}
    renderRest={renderListing}
  />
)

export default App
