import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/images/logo.png'

const Footer = () => (
  <footer className="footer">
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-lg-7">
          <img src={logo} width="77" alt=""/>
        </div>
          {/* <div className="col-6 col-md-3 col-lg-3">
            <h5>Dserve</h5>
            <ul>
              <li><a href="#">App</a></li>
              <li><a href="#">Restaurants</a></li>
              <li><a href="#">Contacts</a></li>
            </ul>
          </div>
          <div className="col-6 col-md-3 col-lg-2">
            <h5>Follow us</h5>
            <ul>
              <li><a href="#">Facebook</a></li>
              <li><a href="#">Instagram</a></li>
              <li><a href="#">Linkedin</a></li>
            </ul>
          </div> */}
      </div>
      <div className="copy">
        <p>© 2018 DSERVE. All rights reserved.</p>
        <p><Link to="/terms-and-conditions">Terms & Conditions</Link></p>
        <p><Link to="/privacy-policy">Privacy Policy</Link></p>
      </div>
    </div>
  </footer>
)

export default Footer
