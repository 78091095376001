import React, { useEffect } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`


export default () => {

  useEffect(() => {
    const timer = setTimeout(() => {
      window.open('https://forms.gle/cj1ZsEBxDgPvJdcZ6', '_self')
    }, 1000)
    return () => clearTimeout(timer)
  })

  return (
    <div className="body">
      <Container>
        Redirecting...
      </Container>
    </div>
  )
}