import React from 'react'

import AdMobile from "./AdMobile"

//Background images
import bgH1 from "../assets/images/bg-h-1.jpg"
import bgH1mobile from "../assets/images/bg-h-1@2x.jpg"
import bgH2 from "../assets/images/bg-h-2.jpg"
import bgH2mobile from "../assets/images/bg-h-2@2x.jpg"
import bgH3 from "../assets/images/bg-h-3.jpg"
import bgH3mobile from "../assets/images/bg-h-3@2x.jpg"

const AppContent = ({
  titleProps,
  sectionOneProps,
  sectionTwoProps,
  sectionThreeProps,
  adProps,
  renderRest
}) => {
  const titleImgHeight = titleProps.imgHeight ? titleProps.imgHeight : undefined

  return (
    <div className="body">
      <div className="hero">
        <div className="container">
          <div className="row">
            <div className="col-sm-8 col-md-7 col-lg-6 offset-lg-1">
              <h1>{titleProps.title}</h1>
              <div className="row">
                <div className="col-lg-10">
                  <p>{titleProps.text}</p>
                </div>
              </div>
              {titleProps.renderAfterText && titleProps.renderAfterText()}
            </div>
          </div>
          <div className="image" style={{ height: titleImgHeight, ...titleProps.imgStyle }}>
            <img src={titleProps.src} style={{ height: titleImgHeight, ...titleProps.style }} srcSet={`${titleProps.srcMobile}`} alt="" />
          </div>
        </div>
      </div>

      <div className="features">
        <div className="container">
          <section>
            <article>
              <div className="row align-items-center">
                <div className="col-md-6 order-md-2">
                  <div className="row">
                    <div className="col-lg-10">
                      <h2>{sectionOneProps.title}</h2>
                      <p>{sectionOneProps.text}</p>
                      {sectionOneProps.renderAfterText && sectionOneProps.renderAfterText()}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 order-md-1">
                  <div className="image">
                    <div
                      className="bg non-retina"
                      style={{ backgroundImage: `url(${bgH1})` }}
                    />
                    <div
                      className="bg retina"
                      style={{
                        backgroundImage: `url(${bgH1mobile})`
                      }}
                    />
                    <img
                      src={sectionOneProps.src}
                      srcSet={`${sectionOneProps.srcMobile}`}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </article>
            <article>
              <div className="row align-items-center">
                <div className="col-md-6 col-lg-5 offset-lg-1">
                  <h2>{sectionTwoProps.title}</h2>
                  <p>{sectionTwoProps.text}</p>
                  {sectionTwoProps.renderAfterText && sectionTwoProps.renderAfterText()}
                </div>
                <div className="col-md-6 col-lg-6 img-width">
                  <div className="image">
                    <div
                      className="bg non-retina"
                      style={{ backgroundImage: `url(${bgH2})` }}
                    />
                    <div
                      className="bg retina"
                      style={{
                        backgroundImage: `url(${bgH2mobile})`
                      }}
                    />
                    <img
                      src={sectionTwoProps.src}
                      srcSet={`${sectionTwoProps.srcMobile}`}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </article>
            <article>
              <div className="row align-items-center">
                <div className="col-md-6 order-md-2">
                  <div className="row">
                    <div className="col-lg-10">
                      <h2>{sectionThreeProps.title}</h2>
                      <p>{sectionThreeProps.text}</p>
                      {sectionThreeProps.renderAfterText && sectionThreeProps.renderAfterText()}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 order-md-1">
                  <div className="image">
                    <div
                      className="bg non-retina"
                      style={{ backgroundImage: `url(${bgH3})` }}
                    />
                    <div
                      className="bg retina"
                      style={{
                        backgroundImage: `url(${bgH3mobile})`
                      }}
                    />
                    <img
                      src={sectionThreeProps.src}
                      srcSet={`${sectionThreeProps.srcMobile}`}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </article>
          </section>
        </div>
      </div>

      <AdMobile
        title={adProps.title}
        subtitle={adProps.text}
        noPhoto
      />

      {renderRest && renderRest()}
    </div>
  )
}

export default AppContent
