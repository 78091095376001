import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fac667;
`

const Title = styled.h1`
  margin-bottom: 20px;
`

const StyledSub = styled.h3`
  :hover {
    color: #483BF4;
    pointer: cursor;
  }
`

const PageNotFound = () => {
  return (
    <div className="body">
      <Container>
        <Title>Page not found (404)</Title>
        <Link to='/restaurants'>
          <StyledSub>Check out our restaurants!</StyledSub>
        </Link>
      </Container>
    </div>
  )
}

export default PageNotFound
