import React, { Component } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

import SectionHeader from '../components/SectionHeader'
import IconBack from '../assets/images/arrow-back.svg'
import IconForward from '../assets/images/arrow-forward.svg'
import IconBackHover from '../assets/images/arrow-back-hover.svg'
import IconForwardHover from '../assets/images/arrow-forward-hover.svg'
import { FlexButton, FlexDivCentered } from '../components/UI'

const Container = styled.div`
  position: relative;
  width: 100%;
`

const ControlsContainer = styled(FlexDivCentered)`
  z-index: 5;
  position: absolute;
  top: 0;
  right: 0;

  @media screen and (max-width: 767px) {
    display: none;
  }
`

const SliderButton = styled(FlexButton)`
  height: 44px;
  width: 44px;
  border: 2px solid rgba(153, 156, 169, .15);
  border-radius: 22px;
  cursor: pointer;
  ${({ margin }) => margin ? 'margin-right: 16px;' : null};
  background: url(${({ margin }) => margin ? IconBack : IconForward});
  background-repeat: no-repeat;
  background-position: center;
  transition: .5s ease-in-out;

  &:hover {
    background: url(${({ margin }) => margin ? IconBackHover : IconForwardHover});
    background-repeat: no-repeat;
    background-position: center;
    border: 2px solid rgba(72, 59, 244, .15)
  }
`

const SliderStyled = styled(Slider)`
  margin: 0 ${({ menu }) => menu ? '-16px' : '-20px'};
  margin-bottom: 70px;

  .slick-slide {
    padding: 15px 10px;
    outline: none;
  }

  .slick-track {
    display: flex;
  }

  ${({ hideDuplicate }) => {
    if (hideDuplicate) {
      return `
        .slick-cloned {
          display: none !important;
        }
      `
    } else {
      return undefined
    }
  }}


  ${({ controlHeight }) => {
    if (controlHeight) {
      return `
        .slick-list {
          width: 100% !important;
          .slick-track {
            width: 100% !important;
          }
        }
      `
    } else {
      return undefined
    }
  }}

  @media screen and (max-width: 767px) {
    margin: 0;
    padding: ${({ menu }) => menu ? '0px' : '0px 24px'};
    overflow: visible;
    margin-bottom: 45px;

    .slick-list {
      padding: ${({ menu }) => menu ? '0px 15px' : '0px'};
    }

    .slick-list, .slick-track {
      overflow: visible;
    }

    .slick-slide {
      padding: ${({ menu }) => menu ? '15px 15px' : '15px 8px'};
      outline: none;
    }
  }
`

const PagingContainer = styled.div`
  @media screen and (max-width: 767px) {
    margin: 0 32px;
  }
`

const Paging = styled(FlexDivCentered)`
  width: 100%;
  height: 1px;
  border: 3px solid rgba(153, 156, 169, 0.2);
  background-color: rgba(153, 156, 169, 0.2);
  border-radius: 50px;
  position: relative;
`

const PagingMarker = styled.div`
  width: calc(100% / ${({ count }) => count});
  background: black;
  height: 1px;
  border: 3px solid #000000;
  border-radius: 50px;
  margin-right: auto;
  margin-left: calc((100% / ${({ count }) => count}) * ${({ slideIndex }) => slideIndex})
`

class NewSlider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeSlide: 0
    }

    this.nextSlide = this.nextSlide.bind(this)
    this.previousSlide = this.previousSlide.bind(this)
  }

  //slick next and previous broken on initial change
  nextSlide() {
    // const { activeSlide } = this.state
    // const { list } = this.props
    // let index = activeSlide

    // if (activeSlide + 1 > list.length - 1) {
    //   index = 0
    // } else {
    //   index = index + 1
    // }

    // this.sliderRef.slickGoTo(index)
    this.sliderRef.slickNext()
  }

  previousSlide() {
    // const { activeSlide } = this.state
    // const { list } = this.props
    // let index = activeSlide

    // if (activeSlide - 1 < 0) {
    //   index = list.length - 1
    // } else {
    //   index = index - 1
    // }

    // this.sliderRef.slickGoTo(index)
    this.sliderRef.slickPrev()
  }

  render() {
    const { title, template: CardComponent, displayCount, menu, list, click, ...props } = this.props
    const settings = {
      initialSlide: 0,
      dots: false,
      slidesToShow: displayCount,
      arrows: false,
      touchThreshold: 3000,
      beforeChange: (oldIndex, newIndex) => this.setState({ activeSlide: newIndex < 0 ? 0 : newIndex }),
      responsive: [
        {
          breakpoint: 767,
          settings: {
            dots: false,
            slidesToShow: 1,
            arrows: false,
            touchThreshold: 3000,
            variableWidth: true
          }
        },
        {
          breakpoint: 992,
          settings: {
            dots: false,
            slidesToShow: 2,
            arrows: false,
            touchThreshold: 3000,
            variableWidth: true,
            centerMode: menu ? true : false
          }
        },
        {
          breakpoint: 1025,
          settings: {
            dots: false,
            slidesToShow: displayCount - 1,
            arrows: false,
            touchThreshold: 3000,
            variableWidth: true,
            infinite: true,
            centerMode: menu ? true : false
          }
        }
      ]
    }
    const displayControls = list.length > displayCount

    return (
      <Container>
        <SectionHeader title={title} />
        {displayControls ?
          <ControlsContainer>
            <SliderButton margin onClick={this.previousSlide} />
            <SliderButton onClick={this.nextSlide} />
          </ControlsContainer> :
          null
        }

        <SliderStyled ref={slider => this.sliderRef = slider} {...settings} menu={menu} hideDuplicate={!displayControls} controlHeight={displayCount >= list.length} {...props}>
          {list.map((obj, index) => <CardComponent key={obj.id} {...obj} click={click} />)}
        </SliderStyled>

        {displayControls ?
          <PagingContainer>
            <Paging>
              <PagingMarker count={list.length} slideIndex={this.state.activeSlide} />
            </Paging>
          </PagingContainer> :
          null
        }
      </Container>
    )
  }
}

export default withRouter(NewSlider)
