import React, { Component } from 'react'
import styled from 'styled-components'

import locationLogo from '../assets/images/location-logo.svg'
import { FlexDivCentered } from '../components/UI'

const cities = {
  'All': { },
  'Vilnius': {
    lat: '54.687157',
    lng: '25.279652'
  },
  'Kaunas': {
    lat: '54.896870',
    lng: '23.892429'
  },
  'Klaipėda': {
    lat: '55.710800',
    lng: '21.131809'
  },
  'Alytus': {
    lat: '54.3961344',
    lng: '24.0459268'
  }
}

const LocationContainer = styled(FlexDivCentered)`
  position: relative;
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  height: 40px;
  padding-right: 44px;
  border-right: 1px solid rgba(153, 156, 169, .2);
`
const Location = styled.span`
  white-space: pre;
  ${({ type }) => {
    switch (type) {
      case 'primary':
        return `
          color: #000000;
        `
      case 'secondary':
        return `
          color: rgba(153, 156, 169, .8);
        `
      default:
        return 'color: #000000;'
    }
  }}
`

const LocationLogo = styled.img`
  padding-bottom: 5px;
  margin-right: 19px;
`

const CityListContainer = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  background: #483AF4;
  border-radius: 8px;
  border: 1.5px solid rgba(36, 43, 82, 0.05);
  box-shadow: 0 4px 25px 0 rgba(14, 36, 70, 0.12);
  height: 238px;
  width: 224px;
  padding: 32px 6px 32px 32px;
  z-index: 9;
  display: ${({ cardOpen }) => cardOpen ? 'block' : 'none'};
`

const ListContainer = styled.div`
  overflow-y: scroll;
  padding-right: 5px;
  height: 100%;

  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: none;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #6053FF;
    border-radius: 3.5px;
    height: 98px;
    width: 5px;
  }
`

const StyledList = styled.ul`
  li {
    color: #FFFFFF;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    height: 22px;
    line-height: 22px;
    width: 124px;
    margin-bottom: 16px;
    cursor: pointer;
  }

  *:last-child {
    margin-bottom: 0;
  }
`

const Cities = React.forwardRef((props, ref) => (
  <CityListContainer cardOpen={props.cardOpen} ref={ref}>
    <ListContainer>
      <StyledList>
        {Object.keys(cities).map((city, index) => <li key={index} onClick={() => props.setLocation(city)}>{city}</li>)}
      </StyledList>
    </ListContainer>
  </CityListContainer>
))

class City extends Component {
  constructor(props) {
    super(props)

    this.state = {
      city: 'All',
      locationCardOpen: false
    }
    this.citiesContainerRef = React.createRef()

    this.toggleCitiesCard = this.toggleCitiesCard.bind(this)
    this.setLocation = this.setLocation.bind(this)
    this.handleDocumentClick = this.handleDocumentClick.bind(this)
  }

  toggleCitiesCard() {
    this.setState(
      ({ locationCardOpen }) => ({ locationCardOpen: !locationCardOpen }),
      () => {
        if (this.state.locationCardOpen) {
          document.addEventListener("mousedown", this.handleDocumentClick)
        }
      }
    )
  }

  setLocation(location) {
    const { lat, lng } = cities[location]

    this.setState({ city: location })
    this.props.getRestaurants({lat, lng})
    this.toggleCitiesCard()
  }


  handleDocumentClick(e) {
    if (!this.citiesContainerRef.current.contains(e.target)) {
      this.setState({ locationCardOpen: false })
    }
  }

  render() {
    return (
      <LocationContainer>
        <div onClick={this.toggleCitiesCard}>
          <LocationLogo src={locationLogo} alt="location-logo"/>
          <Location type={'primary'}>Location: </Location>
          <Location type={'secondary'}>{this.state.city}</Location>
        </div>
        <Cities
          cardOpen={this.state.locationCardOpen}
          setLocation={this.setLocation}
          ref={this.citiesContainerRef}
          click={this.cityRestaurants} />
      </LocationContainer>
    )
  }
}

export default City
