import Api from '../utilities/Api'

export const restaurants = {
  state: {
    list: [],
    activeRestaurant: null,
    restaurantInfo: {}
  },
  reducers: {
    setRestaurants(state, payload) {
      return {
        ...state,
        list: payload
      }
    },
    setActiveRestaurant(state, payload) {
      return {
        ...state,
        activeRestaurant: payload
      }
    },
    setRestaurantInfo(state, payload) {
      return {
        ...state,
        restaurantInfo: payload
      }
    }
  },
  effects: (dispatch) => ({
    async fetchRestaurantsAsync(payload, rootState) {

      const restaurants = await Api.fetchRestaurants(payload)
      dispatch.restaurants.setRestaurants(restaurants)
    },

    async fetchRestaurantAsync(payload, rootState) {
      const restaurant = await Api.fetchRestaurantInfo(payload)
      dispatch.restaurants.setRestaurantInfo(restaurant)
    }
  })
}

export const restaurantMenu = {
  state: {
    menu: {}
  },
  reducers: {
    setMenu(state, payload) {


      state = {
        ...state,
        menu: payload
      }

      return state
    }
  },
  effects: (dispatch) => ({
    async fetchRestaurantMenuAsync(payload, rootState) {
      const menu = await Api.fetchRestaurantMenu(payload)

      dispatch.restaurantMenu.setMenu(menu)
    }
  })
}
