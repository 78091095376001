import React from "react";

const TermsAndConditions = props => (
  <div className="body text-content">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h2>Taisyklės</h2>
          <ol>
            <li>
              <strong>
                <strong>Sąvokos</strong>
              </strong>
              <ol>
                <li>
                  <em>Programėlė</em> &ndash; mobiliajam įrenginiui skirta
                  programa &bdquo;Dserve&ldquo;;
                </li>
                <li>
                  <em>Vartotojas</em> &ndash; bet kuris Programėlėje
                  užsiregistravęs asmuo.
                </li>
                <li>
                  <em>Dserve</em> &ndash; UAB &bdquo;Maisto mylėtojai&ldquo;,
                  juridinio asmens kodas 304842657, registruotos buveinės
                  adresas Raudondvario pl. 131, Kaunas.&nbsp;
                </li>
                <li>
                  <em>Partneris </em>&ndash; maitinimo įstaiga, kurios maitinimo
                  paslaugas Vartotojas gali užsisakyti per Programėlę. Partnerių
                  sąra&scaron;as pateikiamas Programėlėje.
                </li>
                <li>
                  <em>Paslaugos</em> &ndash; Paslaugų teikėjo per Programėlę
                  teikiamos tarpininkavimo paslaugos tarp Vartotojo ir Partnerio
                  Taisyklėse nustatyta tvarka.&nbsp;
                </li>
                <li>
                  <em>Taisyklės</em> &ndash; &scaron;ios Programėlės naudojimo
                  ir Paslaugų teikimo taisyklės.
                </li>
                <li>
                  <em>Privatumo politika </em>
                  <strong>- </strong>Programėlėje pateikta Privatumo politika,
                  nustatanti Vartotojo duomenų rinkimo, saugojimo bei naudojimo
                  tvarką.
                </li>
              </ol>
            </li>

            <li>
              <strong>
                <strong>Bendrosios nuostatos</strong>
              </strong>
              <ol>
                <li>
                  &Scaron;ios Taisyklės yra &scaron;alims privalomas teisinis
                  dokumentas, kuriame nustatomos Vartotojo, Dserve bei Partnerio
                  teisės ir pareigos, Paslaugų teikimo tvarka, &scaron;alių
                  atsakomybė ir kitos su Paslaugų teikimu susijusios nuostatos.
                </li>
                <li>
                  Siekdamas gauti Paslaugas Vartotojas privalo susipažinti su
                  &scaron;iomis Taisyklėmis ir Privatumo politika bei
                  registracijos Programėlėje metu atitinkamame laukelyje
                  pažymėti, jog su jomis susipažino ir sutinka. Jeigu Vartotojas
                  su Taisyklėmis ir/ar Privatumo politika nesutinka, Paslaugos
                  jam nėra teikiamos.
                </li>
                <li>
                  Programėlėje užsiregistruoti ir gauti Dserve teikiamas
                  Paslaugas turi teisę tik veiksnūs fiziniai asmenys.
                  Nepilnamečiai nuo keturiolikos iki a&scaron;tuoniolikos metų
                  amžiaus naudotis Paslaugomis turi teisę tik turėdami tėvų arba
                  globėjų sutikimą, i&scaron;skyrus atvejus, kai jie
                  savaranki&scaron;kai disponuoja savo pajamomis. Vartotojas,
                  registruodamasis Programėlėje ir sutikdamas su &scaron;iomis
                  Taisyklėmis, tuo patvirtina, kad jis turi teisę gauti
                  Paslaugas.
                </li>
                <li>
                  Visos intelektinės nuosavybės teisės į &bdquo;Dserve&ldquo;
                  prekės ženklą ir Programėlę (jos programinį kodą, dizainą,
                  duomenų bazę, nuotraukas, apra&scaron;ymus ir pan.) priklauso
                  Dserve arba Dserve turi teisę jomis naudotis. Joks
                  naudojimasis Programėle ar Paslaugomis negali būti suprantamas
                  kaip pagrindas suteikti ar perleisti Vartotojui
                  auk&scaron;čiau nurodytas intelektinės nuosavybės teises.
                </li>
              </ol>
            </li>

            <li>
              <strong>
                <strong>Paslaugų teikimo tvarka</strong>
              </strong>
              <ol>
                <li>
                  Vartotojui suteikiama galimybė Programėlėje susikurti paskyrą
                  ir pasirinktoje Partnerio maitinimo įstaigoje rezervuoti
                  vietą, užsisakyti maitinimo paslaugas vietoje arba su
                  pristatymu, atsiskaityti už paslaugas, gauti ir naudotis
                  nuolaidomis, specialiais pasiūlymais, palikti atsiliepimus,
                  naudotis kitomis Programėlės teikiamomis paslaugomis.&nbsp;
                </li>
                <li>
                  Pasirinktos Partnerio maitinimo įstaigos vietos rezervavimo
                  ir/ar maitinimo paslaugų užsakymo (toliau &ndash; &bdquo;
                  <strong>Užsakymas</strong>&ldquo;) forma laikoma užpildyta,
                  kai joje nurodoma konkreti maitinimo įstaiga, pageidaujamas
                  paslaugų suteikimo laikas, rezervuojama vieta nurodytam žmonių
                  skaičiui (i&scaron;skyrus, jeigu užsisakoma
                  i&scaron;sine&scaron;imui) ir/ar užsakomos konkrečios
                  maitinimo paslaugos, pristatymo adresas (jeigu reikalinga
                  pristatymo paslauga). Užsakymo formoje Vartotojui taip pat
                  nurodoma už paslaugas mokėtina suma, Partnerio taikomos (jeigu
                  taikomos) papildomos sąlygos Užsakymui ir/ar nuolaidos ar
                  specialaus pasiūlymo panaudojimui.&nbsp;
                </li>
                <li>
                  Vartotojas, užsakydamas alkoholinius gėrimus, privalo laikytis
                  toliau nurodytų reikalavimų:
                  <ol>
                    <li>
                      Vartotojas tu būti ne jaunesnis negu 20 metų ir atsiimant
                      užsakytus gėrimus turėti, o papra&scaron;ytas - pateikti
                      amžių patvirtinantį dokumentą. Savo amžių Vartotojas taip
                      pat patvirtina Užsakymo formoje pažymėdamas atitinkamą
                      laukelį;
                    </li>
                    <li>
                      Alkoholiniai gėrimai i&scaron;sine&scaron;imui negali būti
                      užsakomi, t.y. Užsakymo įvykdymo laikas negali būti:
                      pirmadieniais&ndash;&scaron;e&scaron;tadieniais iki 10
                      valandos ir nuo 20 valandos, o sekmadieniais &ndash; iki
                      10 valandos ir nuo 15 valandos.&nbsp;
                    </li>
                  </ol>
                </li>
                <li>
                  Užsakymo formoje komentarams skirtame laukelyje Vartotojas
                  gali pateikti papildomus pageidavimus dėl užsakomų patiekalų.
                  Jeigu Vartotojui susipažinus su informacija apie patiekalų
                  sudėtį (kaip nurodyta 10.5 punkte), Vartotojas nustatė arba
                  Vartotojas įtaria, kad užsakomame patiekale gali būti
                  produktų, kuriems Vartotojas yra alergi&scaron;kas, Vartotojas
                  privalo tai nurodyti komentare. Jeigu Partneris turi tokią
                  galimybę, patiekalas pagaminamas be nurodyto produkto. Jeigu
                  patiekalas negali būti pagamintas be atitinkamo produkto,
                  Partneris Užsakymo netvirtina arba patvirtina be atitinkamo
                  patiekalo.
                </li>
                <li>
                  Vartotojui pateikus Užsakymą, laikoma, kad Vartotojas
                  patvirtina ir sutinka su visa jame nurodyta informacija ir
                  sąlygomis, taip pat sutinka, kad su Užsakovo paskyra susietoje
                  mokėjimo kortelėje būtų rezervuota Užsakymo suma. Jeigu
                  kortelėje esanti suma nepakankama, Užsakymas Partneriui
                  neperduodamas ir nevykdomas.
                </li>
                <li>
                  Vartotojui pateikus Užsakymą, visa jame esanti informacija
                  perduodama Partneriui.
                </li>
                <li>
                  Partneriui patvirtinus Vartotojo Užsakymą, patvirtinimas
                  rodomas Programėlėje ir laikoma, kad tarp &scaron;alių buvo
                  sudaryta teisi&scaron;kai įpareigojanti sutartis Taisyklėse ir
                  Užsakyme nurodytomis sąlygomis. Nuo Vartotojo mokėjimo
                  kortelės, susietos su Vartotojo paskyra Programėlėje,
                  nuskaičiuojama rezervuota Užsakymo suma ir Užsakymas
                  pradedamas vykdyti.
                </li>
              </ol>
            </li>

            <li>
              <strong>
                <strong>Kaina ir specialūs pasiūlymai</strong>
              </strong>
              <ol>
                <li>
                  Vartotojas, naudodamasis Programėle, moka tik už užsakytas
                  maitinimo paslaugas Partnerio nustatytomis kainomis. Dserve
                  teikiamos tarpininkavimo Paslaugos Vartotojui yra
                  nemokamos.&nbsp;
                </li>
                <li>
                  Dserve užtikrina, kad Programėlėje pateiktos maitinimo
                  paslaugų kainos atitinka Partnerio realiu laiku nustatytas
                  kainas.
                </li>
                <li>
                  Dserve ir/arba Partneris Vartotojui gali suteikti įvairių
                  nuolaidų, kuponų ar kitokių specialių pasiūlymų (toliau
                  &ndash; <strong>Pasiūlymas</strong>). Pasiūlymai gali turėti
                  galiojimo terminą ir/ar ribotą panaudojimų skaičių, taip pat
                  specialias panaudojimo sąlygas. Pasibaigus nustatytam
                  terminui, i&scaron;naudojus Pasiūlymą ar naudojant nesilaikant
                  nustatytų sąlygų, Pasiūlymas laikomas negaliojančiu.
                  Alkoholiniams gėrimams ir tabako gaminiams nėra taikomi jokie
                  Vartotojo gauti Pasiūlymai.&nbsp;
                </li>
                <li>
                  Norėdamas pasinaudoti Pasiūlymu, Vartotojas privalo suvesti
                  galiojančio Pasiūlymo kodą tam skirtame Užsakymo laukelyje
                  prie&scaron; pateikiant Užsakymą. Suvedus Pasiūlymo kodą,
                  Užsakyme rodoma galutinė Užsakymo kaina.
                </li>
              </ol>
            </li>

            <li>
              <strong>
                <strong>Apmokėjimas</strong>
              </strong>
              <ol>
                <li>
                  Vartotojas, norėdamas užsisakyti ir atsiskaityti už maitinimo
                  paslaugas Programėle, privalo susieti savo mokėjimo kortelę su
                  savo paskyra vadovaudamasis Programėlėje pateiktomis
                  instrukcijomis. Vartotojas privalo pateikti teisingus ir
                  tikslius, o pasikeitus - atnaujinti mokėjimo kortelės
                  duomenis, taip pat užtikrinti, kad mokėjimo metu susietoje
                  mokėjimo kortelėje būtų pakankamas pinigų likutis sumokėti
                  Užsakymo sumą. Dserve neatsako, jeigu dėl neteisingai nurodytų
                  duomenų, nepakankamo likučio ar kitaip dėl Vartotojo kaltės
                  nepavykus atsiskaityti, Vartotojo Užsakymas yra nepriimamas ar
                  at&scaron;aukiamas.
                </li>
                <li>
                  Užsakymo suma nuskaitoma nuo kortelės kaip nurodyta Taisyklių
                  3.7 punkte.&nbsp;
                </li>
              </ol>
            </li>

            <li>
              <strong>
                <strong>Užsakymo vykdymas</strong>
              </strong>
              <ol>
                <li>
                  Vartotojas privalo atvykti į Užsakyme nurodytą maitinimo
                  įstaigą Užsakyme nurodytu laiku. Vartotojo pateiktas Užsakymas
                  vykdomas 15 minučių tikslumu. Partneriui suteikus Vartotojui
                  rezervuotą vietą ir/ar užsakytus patiekalus, Užsakymas
                  laikomas įvykdytu.&nbsp;
                </li>
                <li>
                  &nbsp;Vartotojui neatvykus laiku, rezervuota vieta ir
                  paruo&scaron;tas Užsakymas saugomas 20 minučių. Vartotojui
                  neatvykus per &scaron;į terminą, Užsakymas laikomas įvykdytu
                  ir Vartotojo sumokėta suma negrąžinama.
                </li>
                <li>
                  Vartotojui neatvykus laiku, kai patiekalai užsakyti
                  i&scaron;sine&scaron;imui, paruo&scaron;tas Užsakymas saugomas
                  20 minučių. Vartotojui neatvykus per &scaron;į terminą,
                  Užsakymas laikomas įvykdytu ir Vartotojo sumokėta suma
                  negrąžinama.
                </li>
                <li>
                  Vartotojas privalo būti Užsakymo metu nurodytoje ir
                  patvirtintoje vietoje, nuo Užsakymo pateikimo iki Užsakyme
                  nurodytų produktų gavimo. Jei Vartotojas nėra jo/jos
                  patvirtintoje vietoje per de&scaron;imt minučių nuo užsakytų
                  produktų pristatymo atvykimo ir Vartotojas neatsako po dviejų
                  pristatymą atliekančio kurjerio bandymų susisiekti, Dserve
                  arba Partneris gali at&scaron;aukti pristatymą ir i&scaron;
                  Vartotojui nebus grąžinti jo sumokėti pinigai už Užsakymą.
                </li>
                <li>
                  Jeigu Vartotojas užsisako alkoholinių gėrimų nesilaikydamas
                  Taisyklių reikalavimų (3.3 punktas), Partneris turi teisę
                  Vartotojui nepateikti alkoholinių gėrimų pagal Užsakymą. Tokiu
                  atveju laikoma, kad Užsakymas negali būti pilnai įvykdytas dėl
                  Vartotojo kaltės ir už alkoholinius gėrimus sumokėta suma gali
                  būti negrąžinama. Visais atvejais dėl sumokėtos sumos ar jos
                  dalies grąžinimo Vartotojui sprendžia Partneris.
                </li>
                <li>
                  Vartotojui užsakius papildomų paslaugų maitinimo įstaigoje
                  nesinaudojant Programėle, &scaron;ios Taisyklės netaikomos, t.
                  y. laikoma, kad tarp Vartotojo ir Partnerio buvo sudarytas
                  atskiras susitarimas ir Dserve nėra laikoma &scaron;io
                  susitarimo &scaron;alimi.&nbsp;
                </li>
                <li>
                  &nbsp;Visos Programėlėje matomos Partnerio siūlomų patiekalų
                  ir/ar maitinimo įstaigos aplinkos nuotraukos ir
                  apra&scaron;ymai yra pateiktos Partnerio. Dserve neatsako už
                  Partnerio suteiktų paslaugų kokybę ir/ar atitikimą
                  Programėlėje pateiktoms nuotraukoms ir apra&scaron;ymams, o
                  bet kokios pretenzijos dėl neatitikimų rei&scaron;kiamos
                  tiesiogiai Partneriui.
                </li>
              </ol>
            </li>

            <li>
              <strong>
                <strong>Atsiliepimai</strong>
              </strong>
              <ol>
                <li>
                  Įvykdžius Užsakymą, Vartotojo Programėlėje gali būti
                  pra&scaron;oma pateikti savo nuomonę, atsiliepimą, įvertinimą
                  ar rekomendaciją (toliau &ndash; <strong>Atsiliepimas</strong>
                  ). Dserve turi teisę Vartotojo pateiktą Atsiliepimą
                  vie&scaron;ai skelbti Programėlėje ir/ar Dserve paskyroje
                  socialiniuose tinkluose nurodant Vartotojo paskyros
                  slapyvardį, o Vartotojui sutikus ir kitus duomenis. Vartotojo
                  vertinimas gali būti tvarkomas statistiniais tikslais.&nbsp;
                </li>
                <li>
                  Teikdamas Atsiliepimą Vartotojas privalo užtikrinti, kad jis
                  nepažeistų vie&scaron;osios tvarkos, kito asmens privatumo,
                  garbės ir orumo, kitų teisės aktų reikalavimų. Atsiliepimas
                  turi būti susijęs su Dserve ir/ar Partnerio teikiamomis
                  paslaugomis.
                </li>
                <li>
                  Dserve nekoreguoja Vartotojo pateiktų Atsiliepimų ir už juos
                  neatsako, tačiau pasilieka teisę pa&scaron;alinti Atsiliepimą,
                  jeigu jis pažeidžia Taisyklių ir/ar taikomų teisės aktų
                  reikalavimus.
                </li>
              </ol>
            </li>

            <li>
              <strong>
                <strong>Vartotojo teisės ir įsipareigojimai</strong>
              </strong>
              <ol>
                <li>
                  Vartotojas, siekdamas gauti Paslaugas, įsipareigoja
                  užsiregistruoti Programėlėje (sukuriant Vartotojo paskyrą) ir
                  pateikti tikslius ir teisingus, registracijos metu
                  reikalaujamus duomenis. Pasikeitus asmens duomenims Vartotojas
                  privalo juos atnaujinti pateikdamas naujus teisingus duomenis.
                  Vartotojo asmens duomenys tvarkomi taip, kaip tai nurodyta
                  Privatumo politikoje.
                </li>
                <li>
                  Vartotojas atsako už prisijungimo informacijos (paskyros
                  prisijungimo vardo ir slaptažodžio) slaptumą ir įsipareigoja
                  neperduoti &scaron;ių duomenų tretiesiems asmenims. Jei
                  Vartotojas praranda prisijungimo duomenis, jis turi kreiptis į
                  Dserve skyriuje &bdquo;Pagalba&ldquo; nurodytomis susisiekimo
                  priemonėmis dėl jo paskyros užblokavimo ar prisijungimo
                  duomenų pakeitimo. Dserve turi teisę pra&scaron;yti Vartotojo
                  patvirtinti savo tapatybę.
                </li>
                <li>
                  Vartotojas įsipareigoja naudotis Programėle bei teikiamomis
                  Paslaugomis tik asmeniniais, nekomerciniais tikslais bei
                  laikydamasis &scaron;ių Taisyklių, vie&scaron;osios tvarkos ir
                  kitų Lietuvos Respublikos teisės aktų reikalavimų.
                </li>
                <li>
                  Vartotojas neturi teisės naudoti Programėlės kito asmens vardu
                  ir/ar sąskaita be to asmens sutikimo.
                </li>
                <li>
                  &nbsp;Vartotojas neturi teisės bet kokiu būdu bandyti
                  prisijungti prie Programėlės administratoriaus teisėmis,
                  gauti, keisti, kopijuoti, naudoti Programėlės programinio
                  kodo, keisti Programėlės turinį ar i&scaron;vaizdą ar kitaip
                  kenkti Programėlės veikimui.&nbsp;
                </li>
                <li>
                  Vartotojas turi teisę bet kada atsisakyti Dserve teikiamų
                  Paslaugų. Tokiu atveju Vartotojas turi i&scaron;trinti savo
                  paskyrą Programėlėje.
                </li>
              </ol>
            </li>

            <li>
              <strong>
                <strong>Dserve teisės ir įsipareigojimai</strong>
              </strong>
              <ol>
                <li>
                  Jei Vartotojas bando pakenkti Programėlės darbo stabilumui
                  ir/ar saugumui ar pažeidžia Taisyklėse nurodytus
                  įsipareigojimus, Dserve turi teisę nedelsiant ir be perspėjimo
                  apriboti ar sustabdyti jam galimybę naudotis Programėle
                  ir/arba panaikinti Vartotojo paskyrą. Bet kokie bandymai
                  pakenkti Programėlės darbo stabilumui yra tiriami ir gali būti
                  perduodami teisėsaugos institucijoms.
                </li>
                <li>
                  Dserve turi teisę savo nuožiūra pakeisti Programėlės veiklą,
                  pobūdį ar formą, laikinai arba visam laikui nutraukti Paslaugų
                  teikimą, apie tai i&scaron; anksto neprane&scaron;ęs
                  Vartotojui.
                </li>
                <li>
                  Dserve įsipareigoja gerbti Vartotojo privatumo teisę į jam
                  priklausančią asmeninę informaciją, nurodytą Vartotojo
                  paskyroje, ir naudoti tokią informaciją Privatumo politikoje
                  ir Taisyklėse nustatyta tvarka.
                </li>
              </ol>
            </li>

            <li>
              <strong>
                <strong>&Scaron;alių atsakomybė</strong>
              </strong>
              <ol>
                <li>
                  Vartotojas yra visi&scaron;kai atsakingas už Programėlėje
                  pateiktų duomenų teisingumą. Jei Vartotojas nepateikia tikslių
                  duomenų ar pateikia neteisingus duomenis registracijos formoje
                  ir/ar Užsakyme, Dserve neatsako už dėl to atsiradusius
                  padarinius.
                </li>
                <li>
                  Vartotojas yra atsakingas už jo pateiktų Atsiliepimų turinį ir
                  atitikimą Taisyklėms bei taikomiems teisės aktų reikalavimams.
                </li>
                <li>
                  Vartotojas pats atsako už visus savo veiksmus, atliktus
                  naudojantis Programėle.
                </li>
                <li>
                  Užsiregistravęs Vartotojas pats atsako už savo prisijungimo
                  duomenų perdavimą tretiesiems asmenims ir i&scaron; to
                  kylančias pasekmes.
                </li>
                <li>
                  Teisės aktų nustatytą informaciją apie Partnerio tiekiamų
                  patiekalų sudėtį ir/ar alergenus pateikia Partneris
                  Programėlėje skelbiamame meniu arba tokią informaciją teikia
                  nurodytu kontaktiniu telefonu. Dserve neatsako už &scaron;ios
                  informacijos teisingumą. Dserve taip pat neatsako, jeigu
                  Vartotojas su pateikta informacija nesusipažino ar
                  patiekalus/produktus vartojo neatsižvelgdamas į tokią
                  informaciją (pavyzdžiui, turėdamas alergiją produkto sudėtyje
                  esančioms medžiagoms ar pan.) ir dėl to patyrė žalą.
                </li>
                <li>
                  Dserve atleidžiamas nuo bet kokios atsakomybės tais atvejais,
                  kai nuostoliai kyla dėl to, jog Vartotojas nesusipažino su
                  &scaron;iomis Taisyklėmis, nors tokia galimybė jam buvo
                  suteikta, ar naudojosi Paslaugomis nesilaikydamas Taisyklių.
                </li>
                <li>
                  Jei Programėlėje yra nuorodos į kitų įmonių, įstaigų,
                  organizacijų ar asmenų el. tinklalapius, Dserve nėra
                  atsakingas už ten esančią informaciją ar vykdomą veiklą, tų
                  tinklalapių neprižiūri, nekontroliuoja ir toms įmonėms bei
                  asmenims neatstovauja.
                </li>
              </ol>
            </li>

            <li>
              <strong>
                <strong>Baigiamosios nuostatos</strong>
              </strong>
              <ol>
                <li>
                  Dserve pasilieka teisę bet kuriuo metu pakeisti, taisyti ar
                  papildyti Taisykles, atsižvelgdamas į teisės aktų nustatytus
                  reikalavimus. Apie bet kokius pakeitimus, taisymus ar
                  papildymus Dserve skelbia Programėlėje.
                </li>
                <li>
                  Jei po Taisyklių pakeitimo Vartotojas toliau naudojasi
                  Programėle, Dserve teikiamomis Paslaugomis ir/arba nesiima
                  jokių aktyvių veiksmų, i&scaron;rei&scaron;kiančių Vartotojo
                  prie&scaron;taravimą, laikoma, kad Vartotojas sutinka su nauja
                  Taisyklių redakcija.
                </li>
                <li>
                  Jeigu Vartotojas nesutinka su nauja Taisyklių redakcija, jis
                  turi nustoti naudotis Paslaugomis ir i&scaron;trinti savo
                  paskyrą i&scaron; Programėlės arba apie tai prane&scaron;ti
                  Dserve elektroninio pa&scaron;to adresu{" "}
                  <a href="mailto:info@dserve.app">info@dserve.app</a>. Tokiu
                  atveju Vartotojo paskyra Programėlėje panaikinama ir Paslaugos
                  Vartotojui nebeteikiamos.
                </li>
                <li>
                  Kai Vartotojo paskyra Programėlėje i&scaron;trinama, paskelbti
                  Vartotojo Atsiliepimai gali būti ir toliau rodomi kaip
                  nurodyta Taisyklių 7.1 punkte.&nbsp;
                </li>
                <li>
                  Dserve visus prane&scaron;imus Vartotojui siunčia Vartotojo
                  paskyroje pateiktu elektroninio pa&scaron;to adresu. Tokiu
                  būdu įteiktas prane&scaron;imas laikomas Vartotojui įteiktu
                  tinkamai.
                </li>
                <li>
                  Taisyklės yra sudarytos vadovaujantis Lietuvos Respublikos
                  teisės aktais. &Scaron;ių Taisyklių pagrindu atsiradusiems
                  &scaron;alių santykiams taip pat taikoma Lietuvos Respublikos
                  teisė.
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
);

export default TermsAndConditions;
