import React from "react";

const PrivacyPolicy = props => (
  <div className="body text-content">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h2>Privatumo politika</h2>
          <ol>
            <li>
              <strong>
                <strong>Sąvokos</strong>
              </strong>
              <ol>
                <li>
                  <em>Programėlė</em> &ndash; mobiliajam įrenginiui skirta
                  programa &bdquo;Dserve&ldquo;;
                </li>
                <li>
                  <em>Vartotojas</em> &ndash; bet kuris Programėlėje
                  užsiregistravęs asmuo.
                </li>
                <li>
                  <em>Dserve</em> &ndash; UAB &bdquo;Maisto mylėtojai&ldquo;,
                  juridinio asmens kodas 304842657, registruotos buveinės
                  adresas Raudondvario pl. 131, Kaunas.&nbsp;
                </li>
                <li>
                  <em>Partneris </em>&ndash; maitinimo įstaiga, kurios maitinimo
                  paslaugas Vartotojas gali užsisakyti per Programėlę. Partnerių
                  sąra&scaron;as pateikiamas Programėlėje.
                </li>
                <li>
                  <em>Paslaugos</em> &ndash; Paslaugų teikėjo per Programėlę
                  teikiamos tarpininkavimo paslaugos tarp Vartotojo ir Partnerio
                  Taisyklėse nustatyta tvarka.&nbsp;
                </li>
                <li>
                  <em>Taisyklės</em> &ndash; Programėlėje pateiktos Programėlės
                  naudojimo ir Paslaugų teikimo taisyklės.
                </li>
                <li>
                  <em>Privatumo politika </em>
                  <strong>- </strong>&scaron;i Privatumo politika, nustatanti
                  Vartotojo duomenų rinkimo, saugojimo bei naudojimo tvarką.
                </li>
              </ol>
              <p>
                Kitos Privatumo politikoje didžiąja raide ra&scaron;omos sąvokos
                suprantamos, kaip jos apibrėžtos Taisyklėse.
              </p>
            </li>

            <li>
              <strong>
                <strong>Bendrosios nuostatos</strong>
              </strong>
              <ol>
                <li>
                  Vartotojo duomenys renkami, saugojami bei tvarkomi
                  vadovaujantis &scaron;ia Privatumo politika, ES Bendruoju
                  duomenų apsaugos reglamentu 2016/679, Lietuvos Respublikos
                  asmens duomenų teisinės apsaugos įstatymu, taip pat kitais
                  taikomais teisės aktais.
                </li>
                <li>
                  Dserve imasi atitinkamų techninių ir organizacinių priemonių,
                  kad užtikrintų visų tvarkomų asmens duomenų apsaugą nuo
                  netyčinio ar neteisėto sunaikinimo arba netyčinio praradimo,
                  pakeitimo, neįgalioto atskleidimo arba prieigos, ir nuo visų
                  kitų neteisėtų apdorojimo formų.
                </li>
                <li>
                  Sutikdamas su &scaron;ia Privatumo politika, Vartotojas
                  sutinka, kad Dserve Vartotojo registracijos Programėlėje metu
                  ir tokios registracijos pagrindu sukurtoje savo asmeninėje
                  paskyroje pateiktus duomenis (vardą, pavardę, el. pa&scaron;to
                  adresą, telefono Nr., nuotrauką, banko atsiskaitomosios
                  kortelės duomenis, taip pat Vartotojo pateiktus Atsiliepimus,
                  Vartotojo paskyros prisijungimo duomenis, adresą (kuris gali
                  būti pateiktas ir vėliau, t.y. ne registracijos metu) ir
                  t.t.), taip pat Programėlės renkamus duomenis (buvimo vieta,
                  dažniausiai lankomos maitinimo įstaigos, Užsakymų istorija
                  (užsakyti patiekalai, pateikti įvertinimai, i&scaron;leista
                  suma ir t.t.), kita Programėlės naudojimo statistinė
                  informacija) valdys ir tvarkys &scaron;ioje Privatumo
                  politikoje bei teisės aktuose numatytais tikslais, priemonėmis
                  ir tvarka.&nbsp;Vartotojo sutikimas,
                  i&scaron;rei&scaron;kiamas užsiregistruojant ir pateikiant
                  asmens duomenis Programėlėje, bei pateikiant asmens duomenis
                  tokios registracijos pagrindu sukurtoje savo asmeninėje
                  paskyroje, yra teisėtas jo duomenų rinkimo ir tvarkymo
                  pagrindas.
                </li>
                <li>
                  Vartotojo duomenis (vardas, pavardė, el. pa&scaron;to adresas)
                  tiesioginės rinkodaros tikslu mes naudojame ir tvarkome
                  Vartotojo sutikimo pagrindu, kuris i&scaron;rei&scaron;kiamas
                  Vartotojui užpildant naujienlai&scaron;kio gavimo formą
                  Programėlėje.
                </li>
                <li>
                  &Scaron;i Privatumo politika taikoma visais atvejais, kai
                  Vartotojas užsiregistruoja Programėlėje ir joje ir/ar jos
                  pagrindu sukurtoje asmeninėje Vartotojo paskyroje pateikia ar
                  koreguoja bet kokius &scaron;io Privatumo politikos skyriaus
                  2.3 &ndash; 2.4&nbsp;punktuose nurodytus savo asmens duomenis,
                  neatsižvelgiant į tai, ar Vartotojui buvo suteiktos Paslaugos,
                  taip pat kai Vartotojas pateikia bet kokią kitą asmeninę
                  informaciją Programėlėje (Atsiliepimus ir pan.).
                </li>
              </ol>
            </li>

            <li>
              <strong>
                <strong>Asmens duomenų rinkimas, tvarkymas, saugojimas</strong>
              </strong>
              <ol>
                <li>
                  Visi Programėlėje renkami duomenys yra naudojami &scaron;iais
                  tikslais:
                  <ol>
                    <li>
                      Vartotojo registracijos Programėlėje atlikimui ir
                      registracijos pagrindu sukurtos Vartotojo paskyros
                      administravimui;
                    </li>
                    <li>
                      suteikti informaciją kurjeriams, kuri būtina Užsakymui
                      vykdyti;
                    </li>
                    <li>
                      informacijos, susijusios su Paslaugų teikimu, tvarkymui ir
                      apdorojimui;
                    </li>
                    <li>
                      teikiamų Paslaugų kokybės užtikrinimui ir gerinimui;
                    </li>
                    <li>Paslaugų teikimui ir suteiktų Paslaugų įvertinimui;</li>
                    <li>buhalterinės apskaitos tvarkymui;</li>
                    <li>
                      problemų, susijusių su Paslaugų teikimu, sprendimui;
                    </li>
                    <li>statistiniais ir tiesioginės rinkodaros tikslais;</li>
                    <li>
                      kitais teisėtais su Paslaugų teikimu susijusiais tikslais,
                      įskaitant Vartotojo Atsiliepimų skelbimui Programėlėje
                      ir/ar Dserve paskyroje socialiniuose tinkluose.
                    </li>
                  </ol>
                </li>
                <li>
                  Vartotojo duomenys statistiniais tikslais tvarkomi
                  nuasmeninti, t.y. taip, kad nebūtų atskleista Vartotojo asmens
                  tapatybė.
                </li>
                <li>
                  Vartotojo asmens duomenys, kuriuos saugoti Dserve turi teisinę
                  pareigą (pavyzdžiui, duomenys susiję su buhalterine apskaita,
                  teisminiu ginču ir pan.), saugomi tiek, kiek nurodyta teisės
                  aktuose.
                </li>
                <li>
                  Asmens duomenis, tvarkomus tiesioginės rinkodaros tikslu,
                  Dserve saugo ne ilgiau nei 3metus nuo Dserve
                  naujienlai&scaron;kio užsakymo dienos, nebent Vartotojas savo
                  sutikimą dėl tiesioginės rinkodaros at&scaron;aukia anksčiau.
                </li>
                <li>
                  Visi kiti Vartotojo asmens duomenys yra saugomi tol, kol
                  galioja Vartotojo asmeninė paskyra Programėlėje, bet ne ilgiau
                  kaip 10 metų nuo paskutinių Vartotojo aktyvių veiksmų atlikimo
                  (prisijungimo prie Programėlės paskyros). Duomenys
                  i&scaron;trinami anksčiau, jeigu yra gautas Vartotojo
                  prie&scaron;taravimas dėl tokio tolesnio duomenų
                  saugojimo.&nbsp;
                </li>
              </ol>
            </li>

            <li>
              <strong>
                <strong>Asmens duomenų perdavimas tretiesiems asmenims</strong>
              </strong>
              <ol>
                <li>
                  Dserve turi teisę perduoti Vartotojo duomenis tretiesiems
                  Europos Ekonominėje Erdvėje esantiems asmenims tiek, kiek tai
                  yra susiję su Paslaugų teikimu:
                  <ol>
                    <li>
                      Partneriui, teikiami Vartotojo duomenys, reikalingi
                      Užsakymo vykdymui ir apmokėjimui, taip pat Vartotojo
                      pateiktas Atsiliepimas;
                    </li>
                    <li>
                      Mokėjimų tarpininkui ir Vartotojo bankui teikiami
                      Vartotojo su savo paskyra susietos mokėjimo kortelės
                      duomenys, pateikto Užsakymo suma, mokėjimo nurodymas, kad
                      Užsakymo suma būtų nuskaičiuota nuo mokėjimo kortelės ir
                      perduota Partneriui;
                    </li>
                    <li>
                      Kurjeriui, teikiami Vartotojo duomenys, reikalingi
                      Užsakymo pristatymui.
                    </li>
                    <li>
                      Tretiesiems asmenims, kurie padeda Dserve administruoti
                      Programėlę, gali būti prieinami Vartotojo Programėlėje
                      pateikti duomenys. Tokiais trečiaisiais asmenimis gali
                      būti duomenų bazių programinės įrangos tiekėjai, duomenų
                      bazių administravimo paslaugų teikėjai, debesijos paslaugų
                      teikėjai ir pan.&nbsp;
                    </li>
                    <li>
                      Jūsų asmens duomenys taip pat gali būti pateikiami
                      valdžios arba teisėsaugos įstaigoms, pvz., policijai arba
                      priežiūros institucijoms, tačiau tik joms pareikalavus ir
                      tik tada, kai reikalaujama pagal taikomą įstatymą arba
                      siekiant užtikrinti Dserve teises.
                    </li>
                  </ol>
                </li>

                <li>
                  Dserve naudojasi mokėjimų tarpininko paslaugomis. Vartotojo
                  asmens duomenis, susijusius su atliekamu mokėjimu už maitinimo
                  paslaugas, mokėjimų tarpininkas valdo kaip atskiras duomenų
                  valdytojas.&nbsp;
                </li>
                <li>
                  Dserve turi teisę bet kuriuo metu pakeisti mokėjimų
                  tarpininką. Pasikeitus mokėjimų tarpininkui, Vartotojo asmens
                  duomenys, yra perduodami naujajam mokėjimų tarpininkui.&nbsp;
                </li>
                <li>
                  Jeigu Jūsų asmens duomenys perduodami į trečiąją &scaron;alį,
                  kurioje duomenų apsaugos įstatymai nesuteikia tokio paties
                  lygio apsaugos, koks galioja Europos Ekonominėje Erdvėje, mes
                  užtikriname tinkamą duomenų apsaugą, naudodami papildomas
                  teisines priemones, pvz., &bdquo;Privacy Shield&ldquo;
                  sertifikatus ir / arba standartines sutarčių sąlygas.
                </li>
              </ol>
            </li>

            <li>
              <strong>
                Asmens duomenų pateikimas, koregavimas, pa&scaron;alinimas ir
                kitos Vartotojo teisės
              </strong>
              <ol>
                <li>
                  Vartotojas turi pateikti asmens duomenis registracijos
                  Programėlėje metu, bei taip pat &scaron;ios registracijos
                  pagrindu sukurtoje asmeninėje Vartotojo paskyroje, jeigu
                  siekia gauti Dserve teikiamas Paslaugas.
                </li>
                <li>
                  Vartotojas užtikrina, kad jo Programėlėje pateikti asmens
                  duomenys būtų teisingi bei aktualūs, t.y. pasikeitus asmens
                  duomenims Vartotojas turi juos atnaujinti pateikdamas naujus
                  teisingus duomenis. Kitu atveju Dserve negalės atlikti
                  Vartotojo registracijos Programėlėje, administruoti tokios
                  registracijos pagrindu sukurtos Vartotojo asmeninės paskyros,
                  bei suteikti Vartotojui Paslaugas.
                </li>
                <li>
                  Vartotojas turi teisę kreiptis į Dserve elektroniniu
                  pa&scaron;tu{" "}
                  <a href="mailto:info@dserve.app">info@dserve.app</a>{" "}
                  pra&scaron;yti, kad Dserve leistų susipažinti su tvarkomais
                  Vartotojo asmens duomenimis bei juos i&scaron;taisytų, jeigu
                  Paslaugų teikėjo turimi duomenys yra neteisingi ar netikslūs,
                  juos i&scaron;trintų, apribotų duomenų tvarkymą, taip pat
                  teisę nesutikti su tolesniu asmens duomenų tvarkymu, teisę
                  gauti savo asmens duomenis susistemintu, įprastai naudojamu ir
                  kompiuterio skaitomu formatu (teisė į duomenų perkeliamumą).
                  Paslaugų teikėjui tam tikri Vartotojo duomenys (pvz., vardas,
                  pavardė, mokėjimo kortelės duomenys) gali būti būtini
                  Paslaugoms teikti. Vartotojui tokių duomenų nepateikus arba
                  papra&scaron;ius juos i&scaron;trinti vėliau, Paslaugų
                  teikimas gali būti apsunkinamas ir dėl &scaron;ios priežasties
                  Paslaugos Vartotojui gali būti nebeteikiamos.
                </li>
                <li>
                  Vartotojas taip pat turi teisę bet kuriuo metu kreipdamasis
                  elektroniniu pa&scaron;tu{" "}
                  <a href="mailto:info@dserve.app">info@dserve.app</a>{" "}
                  at&scaron;aukti savo sutikimą rinkti ir tvarkyti savo asmens
                  duomenis, nedarant poveikio jo duomenų rinkimo ir tvarkymo iki
                  sutikimo at&scaron;aukimo teisėtumui. Vartotojui
                  at&scaron;aukus savo sutikimą, Vartotojo paskyra Programėlėje
                  panaikinama ir Paslaugos Vartotojui nebeteikiamos.
                </li>
                <li>
                  Vartotojas gali pasinaudoti auk&scaron;čiau nurodytomis savo
                  teisėmis kreipiantis į Dserve elektroniniu pa&scaron;tu{" "}
                  <a href="mailto:info@dserve.app">info@dserve.app</a>, arba
                  registruotu pa&scaron;tu auk&scaron;čiau &scaron;ioje
                  Privatumo politikoje nurodytais rekvizitais.
                </li>
                <li>
                  Vartotojui manant, kad jo asmens duomenys yra tvarkomi
                  pažeidžiant duomenų apsaugos reikalavimus, jis turi teisę
                  pateikti skundą Valstybinei duomenų apsaugos inspekcijai.
                </li>
              </ol>
            </li>

            <li>
              <strong>
                <strong>Baigiamosios nuostatos</strong>
              </strong>
              <ol>
                <li>
                  Dserve turi teisę bet kuriuo metu savo nuožiūra i&scaron;
                  dalies ar visi&scaron;kai pakeisti Privatumo politiką apie tai
                  paskelbdamas Programėlėje. Nauja Privatumo politikos redakcija
                  įsigalioja nuo jos paskelbimo Programėlėje dienos.
                </li>
                <li>
                  Jei po Privatumo politikos pakeitimo Vartotojas toliau
                  naudojasi Programėle, Dserve teikiamomis Paslaugomis ir/arba
                  nesiima jokių aktyvių veiksmų, i&scaron;rei&scaron;kiančių
                  Vartotojo prie&scaron;taravimą, laikoma, kad Vartotojas
                  sutinka su nauja Privatumo politikos redakcija.
                </li>
                <li>
                  Jeigu Vartotojas nesutinka su nauja Privatumo politikos
                  redakcija, jis turi apie tai prane&scaron;ti Paslaugų teikėjui
                  elektroniniu pa&scaron;tu{" "}
                  <a href="mailto:info@dserve.app">info@dserve.app</a>. Tokiu
                  atveju Vartotojo paskyra Programėlėje, atsižvelgiant į
                  Vartotojo pageidavimus, gali būti panaikinama, ir Paslaugos
                  Vartotojui gali būti nebeteikiamos
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
);

export default PrivacyPolicy;
