import React from 'react'
import styled from 'styled-components'

import DarkLoader from '../assets/images/dark-loader.svg'

const Loading = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ContentLoader = (props) => {
  return (
    <Loading {...props}>
      <img src={DarkLoader} alt='loader' />
    </Loading>
  )
}

export default ContentLoader
