import React, { Component } from 'react'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'
import MediaQuery from 'react-responsive'

import SectionHeader from './SectionHeader'
import DishCard from './DishCard'
import MobileDishCard from './MobileDishCard'
import { FlexDivCentered } from '../components/UI'

import arrowDown from '../assets/images/arrow-down.svg'
import arrowDownBlue from '../assets/images/arrow-down-blue.svg'

const CollapseContainer = styled.div`
  position: relative;
  transition: max-height .5s ease-in-out, opacity .5s ease-in-out, padding .5s ease-in-out;
  overflow: hidden;
  width: 100%;
  margin-bottom: 64px;
  height: auto;
  max-height: ${({ height, normalSize }) => normalSize ? 'auto' : height};

  &.section-enter-done {
    max-height: ${({ maxHeight }) => `${maxHeight + 100}px`};
    padding-bottom: 30px;
  }

  &.query-dishes-enter {
    opacity: 0;
  }

  &.query-dishes-enter-active {
    opacity: 1;
  }

  &.query-dishes-exit {
    opacity: 1;
    padding-bottom: 10px;
  }

  &.query-dishes-exit-active {
    opacity: 0;
    padding-bottom: 15px;
  }
`

const Row = styled.div`
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
`

const Col = styled.div`
  box-sizing: border-box;
  flex: 0 0 auto;
  flex-basis: 0;
  flex-grow: 1;
  flex-basis: ${({ col }) => col / 12 * 100}%;
  max-width: ${({ col }) => col / 12 * 100}%;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 40px;
`

const Gradient = styled.div`
  position:absolute;
  transition: opacity .5s ease-in-out, height .5s ease-in-out;
  z-index:2;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(-180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 80%);
  height: 220px;
  display: block;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;

  &.grad-exit-done {
    height: 70px;
    bottom: -12px;
    background: linear-gradient(-180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
  }
`

const DisplayControl = styled(FlexDivCentered)`
  margin-bottom: 18px;
  justify-content: space-between;
  color: #483AF4;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  height: 24px;
  line-height: 24px;
  width: 110px;
  cursor: pointer;

  &:hover {
    color: #000000;
  }
`

const Arrow = styled.img`
  margin-left: 8px;
  transform: ${({ rotate }) => rotate ? 'rotate(180deg)' : null};
`

class DishesContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fullHeight: false,
      hover: false
    }

    this.toggleDivGrow = this.toggleDivGrow.bind(this)
    this.toggleHover = this.toggleHover.bind(this)
  }

  toggleDivGrow() {
    this.setState(({ fullHeight }) => ({ fullHeight: !fullHeight }))
  }

  toggleHover() {
    this.setState(({ hover }) => ({ hover: !hover }))
  }

  render() {
    let list = []
    let displayGradient = false
    const { fullHeight, hover } = this.state

    if (this.props.list) {
      list = this.props.list
    } else {
      list = this.props.category.dishes
      displayGradient = list.length > 6
    }
    const { query, category } = this.props
    let cards = displayGradient ? 8 : list.length
    const mobile = window.innerWidth < 768
    const height = mobile ? 150 * 8 : (Math.round(cards / 2) * 236) + 90
    const maxHeight = mobile ? (list.length * 236) : (Math.round(list.length / 2) * 236) + 90

    return (
      <CSSTransition
        in={fullHeight}
        timeout={0}
        classNames='section'
      >
        <CollapseContainer
          ref={this.props.innerRef}
          normalSize={!displayGradient}
          height={this.props.category ? `${height}px` : 'auto'}
          maxHeight={maxHeight}
        >
          {category ? <SectionHeader title={category.name} count={list.length} /> : null}
          <MediaQuery maxWidth={767}>
            {(matches) => {
              if (matches) {
                return (
                  <div>
                    {list.map((dish, index) => {
                      return (
                        <MobileDishCard key={dish.id} {...dish} query={query} first={index === 0} />
                      )
                    })}
                  </div>
                )
              } else {
                return (
                  <Row>
                    {list.map(dish => {
                      return (
                        <Col key={dish.id} col='6'>
                          <DishCard {...dish} query={query} />
                        </Col>
                      )
                    })}
                  </Row>
                )
              }
            }}
          </MediaQuery>
          {displayGradient ? (
            <CSSTransition
              in={!fullHeight}
              timeout={0}
              classNames='grad'
            >
              <Gradient>
                <DisplayControl onClick={this.toggleDivGrow} onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover}>
                  {fullHeight ? 'Show less' : 'Show more'}
                  <Arrow src={hover ? arrowDown : arrowDownBlue} rotate={fullHeight ? 1 : 0} alt="arrow"/>
                </DisplayControl>
              </Gradient>
            </CSSTransition>
          ) : null}
        </CollapseContainer>
      </CSSTransition>
    )
  }
}
export default React.forwardRef((props, ref) => <DishesContainer {...props} innerRef={ref} />)
