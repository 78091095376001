import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Logo from '../assets/images/logo.png'
import ContentLoader from '../components/ContentLoader'
import { setLogoSource } from '../utilities/utils'

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  position: relative;
  padding-bottom: 70px;
`

const Footer = styled.div`
  position: absolute;
  width: 100%;
  height: 70px;
  bottom: 0;
  box-shadow: 0px -2px 14px 0px rgba(0, 0, 0, 0.07);
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  background-color: #FFF;
`

const FooterText = styled.div`
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0px;
  margin-right: 10px;
`

const ImgLogo = styled.img`
  height: 33px;
`

const CardContainer = styled.div`
  width: 50%;
  padding: 20px;
  &:nth-child(n + 3) {
    padding-top: 0px;
  }
`

const Title = styled.div`
  color: rgba(11, 11, 11, 0.5);
  font-size: 30px;
  font-weight: normal;
  letter-spacing: 0px;
  text-transform: uppercase;
  margin-left: 25px;
  margin-bottom: 20px;
`

const Card = styled.div`
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 25px;
  padding-right: 180px;
  border-radius: 21px;
  box-shadow: 0px 0px 31px 0px rgba(0, 0, 0, 0.15);
  background-color: #FFF;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0px;
  position: relative;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0px;
  }
`

const Price = styled.div`
  transform: translateY(-50%);
  position: absolute;
  right: 0;
  top: 50%;
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
  background: rgb(212, 212, 212);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px 5px 25px;
`

const SmallContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
`

const SmallCard = styled.div`
  border-radius: 21px;
  box-shadow: 0px 0px 31px 0px rgba(0, 0, 0, 0.15);
  background-color: #FFF;
  color: rgb(25, 26, 27);
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 0px;
  width: calc(50% - 40px);
  padding: 32px 25px;
  margin: 0 20px 20px 20px;
`

const ImgCardContainer = styled.div`
  flex-basis: 25%;
  padding: 20px;
  margin-bottom: 20px;
`

const ImageContainer = styled.div`
  padding: 25px 28px;
  box-shadow: 0px 0px 31px 0px rgba(0, 0, 0, 0.15);
  border-radius: 21px;
  border: 1px solid #FFF;
  width: 100%;
  height: 100%;
  display: flex;
  background-image: linear-gradient(192deg, rgba(0, 0, 0, 0) 60%, rgba(255, 255, 255, 0.9) 85%), url(${({ src }) => src});
  background-size: cover;
  align-items: flex-end;
  justify-content: space-between;
`

const ImgTitle = styled.div`
  font-size: 32.4px;
  font-weight: 500;
  letter-spacing: 0px;
`

const CardPrice = styled(ImgTitle)`
  margin-left: 10px;
`

const Category = ({ category, dishes, max }) => (
  <CardContainer>
    <Title>{category.name}</Title>
    {max > 4 ? (
      <SmallContainer>
        {dishes.slice(0, max).map(d => (
          <SmallCard key={d.id}>
            {d.title}
          </SmallCard>
        ))}
      </SmallContainer>
    ) : (
      dishes.slice(0, max).map(d => (
        <Card key={d.id}>
          {d.title}
          <Price>{`€${d.price.toFixed(2)}`}</Price>
        </Card>
      ))
    )}
  </CardContainer>
)

const GridCard = ({ title, id, dishImages, price }) => {
  const src = dishImages.length ? setLogoSource(dishImages[0].imageName, 404, 404) : ''

  return (
    <ImgCardContainer key={id}>
      <ImageContainer src={src}>
        <ImgTitle>{title}</ImgTitle>
        <CardPrice>€{price.toFixed(2)}</CardPrice>
      </ImageContainer>
    </ImgCardContainer>
  )
}

class Tv extends Component {
  intervalId = null
  state = {
    loading: false,
    grid: true
  }

  async componentDidMount() {
    const { fetchRestaurantMenuAsync, match } = this.props
    this.setState({ loading: true })

    await fetchRestaurantMenuAsync(match.params.restaurantId)

    this.setState({ loading: false })
    this.intervalId = setInterval(() => {
      fetchRestaurantMenuAsync(match.params.restaurantId)
      // this.setState(({ grid }) => ({ grid: !grid }))
    }, 30000)
  }

  buildListMenu() {
    const { restaurantMenu } = this.props

    if (restaurantMenu.categories) {
      const categories = restaurantMenu.categories.slice(0, 4)

      if (categories.length > 2) {
        const tempCat = categories[1]
        categories[1] = categories[2]
        categories[2] = tempCat
      }

      return categories.map((cat, i) => {
        const catDishes = restaurantMenu.dishes.filter(d => d.categoryId === cat.id && d.price > 0.5)
        let max
        switch(i) {
          case 2:
            max = 3
            break
          case 3:
            max = 6
            break;
          default:
            max = 4
            break
        }

        return {
          category: cat,
          dishes: catDishes,
          max
        }
      })
    } else {
      return []
    }
  }

  buildGridMenu() {
    const { restaurantMenu } = this.props

    if (restaurantMenu.dishes) {
      return restaurantMenu.categories.map(cat => (
        restaurantMenu.dishes.filter(d => d.categoryId === cat.id && d.price > 1.1)
      )).flat().slice(0, 8)
    } else {
      return []
    }
  }

  render() {
    const { loading, grid } = this.state

    return (
      <Container>
        {loading ? (
          <ContentLoader />
        ) : (
          grid ? (
            this.buildGridMenu().map(d => (
              <GridCard key={d.id} {...d} />
            ))
          ) : (
            this.buildListMenu().map(m => (
              <Category key={m.category.id} {...m} />
            ))
          )
        )}
        <Footer>
          <FooterText>powered by</FooterText>
          <ImgLogo src={Logo} alt="logo" />
        </Footer>
      </Container>
    )
  }
}

export default connect(
  ({ restaurantMenu }) => ({ restaurantMenu: restaurantMenu.menu }),
  ({ restaurantMenu: { fetchRestaurantMenuAsync } }) => ({ fetchRestaurantMenuAsync })
)(withRouter(Tv))
